import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../App.css';
import './AccountVerification.css';

const AccountVerification = () => {
    const { code, id } = useParams();
    const [message, setMessage] = useState("Verifying...");
    const [action, setAction] = useState("/");
    const [actionText, setActionText] = useState("Jetzt OneSurvey WebApp öffnen");

    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/auth/verify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        account: id,
                        code: code
                    })
                });

                if (response.status === 200) {

                    const token = await response.text();
                    sessionStorage.setItem('OSACCESS', token);

                    setMessage("E-Mail-Adresse erfolgreich verifiziert");
                } else {
                    setMessage("Code abgelaufen");
                    setAction("/resend-verify-code");
                    setActionText("Code für die E-Mail-Verifizierung erneut senden");
                }
            } catch (error) {
                setMessage("Fehler bei der Verifizierung");
            }
        };

        verifyAccount();
    }, [id, code]);

    return (
        <div className="verification-container">
            <div className="verification-box">
                <img
                    src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                    className="verification-logo"
                    alt="OneSurvey Logo"
                />
                <p className="verification-message">{message}</p>
                <a href={action} className="verification-action">{actionText}</a>
            </div>
        </div>
    );
};

export default AccountVerification;