import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import '../../../App.css';
import { useNavigate } from "react-router-dom";
import './MyAccount.css';
import LoadingScreen from "../../../components/UIComponents/screens/LoadingScreen";

const MyAccount = () => {
    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState({
        firstname: '',
        lastname: '',
        email: '',
    });

    useEffect(() => {
        loadUserProfile();
        checkAccountSetup();
    }, []);

    const checkAccountSetup = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/setup', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                navigate('/account-setup');
            }
        } catch (error) {
            console.log('Error to retrieve Account Setup Status.');
        }
    };

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    username: account.username,
                    firstname: account.firstname,
                    lastname: account.lastname,
                    email: account.email,
                    phone: account.phone,
                    street: account.street,
                    city: account.city,
                    plz: account.plz,
                    country: account.country,
                    company: account.company,
                    companyname: account.companyName
                });
                setLoading(false);
            } else {
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            console.error('Failed to load user profile', error);
        }
    };

    if (!accessToken) {
        navigate('/login');
        return;
    }


    if (loading) {
        return (
            <LoadingScreen></LoadingScreen>
        );
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Mein OneSurvey Account</h1>
                    <p>Herzlich willkommen in Ihrem OneSurvey Account. Bisher sind leider noch nicht alle erforderlichen Informationen in Ihrem Konto hinterlegt. Bitte fügen Sie Ihre Kontakt- und Adressinformationen hinzu.</p>
                    <a href="/account-setup" className="btn-primary">Jetzt Account vervollständigen</a>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container account-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account</a>
                </span>
                <h1>Mein OneSurvey Account</h1>
                <p>Verwalten Sie die Einstellungen und Daten Ihres Accounts.</p>
                <div className="account-section action-card">
                    <h2>Persönliche Informationen</h2>
                    <p>{userInfo.firstname} {userInfo.lastname}</p>
                    <p>E-Mail: {userInfo.email}</p>
                    <p>Telefon: {userInfo.phone || 'Nicht angegeben'}</p>
                    <a href="/myaccount/personal-information" className="btn btn-primary">Daten ändern</a>
                </div>

                <div className="account-section action-card">
                    <h2>Adressinformationen</h2>
                    <p>{userInfo.companyname || ""}</p>
                    <p>{userInfo.street || 'Nicht angegeben'}</p>
                    <p>{userInfo.plz || 'Nicht angegeben'} {userInfo.city || 'Nicht angegeben'}</p>
                    <p>{userInfo.country || 'Nicht angegeben'}</p>
                    <a href="/myaccount/adress-information" className="btn btn-primary">Daten ändern</a>
                </div>

                <div className="account-section action-card">
                    <h2>Login & Sicherheit</h2>
                    <p>Verwalten Sie Ihr Passwort und die Sicherheitseinstellungen Ihres Kontos.</p>
                    <button className="btn-primary" onClick={()=>navigate('/myaccount/change-password')}>Passwort ändern</button>
                    <button className="btn-primary" onClick={() => navigate('/myaccount/mfa-setup')}>MFA Einstellungen</button>
                </div>

                <div className="account-section action-card">
                    <h2>Ihre Abonnements</h2>
                    <p>Verwalten Sie Ihre Verträge und Abonnements.</p>
                    <a href="/myaccount/subscriptions" className="btn btn-primary">Jetzt verwalten</a>
                </div>

                <div className="account-section account-actions">
                    <button className="btn-primary" onClick={() => navigate('/myaccount/settings')}>Weitere Einstellungen</button>
                    <button className="btn-danger" onClick={() => navigate('/logout')}>Abmelden</button>
                </div>
            </div>

        </div>
    );
}

export default MyAccount;