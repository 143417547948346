import React from 'react';
import './LoadingScreen.css';
import logo from './loading-logo.png';
import Header from "../../Header";

const LoadingScreen = () => {
    return (
        <div>
            <Header />
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default LoadingScreen;
