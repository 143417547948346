import React from "react";
import './TextField.css';

export default function TextField ({field, value, onChange }) {

    return <input type="text" className="textfield"
                  placeholder={field.hintText}
                  value={value}
                  onChange={onChange}
            />;

}

