import React, {useEffect, useState} from "react";
import Header from "../../../components/PublicHeader";
import { useNavigate } from "react-router-dom";
import './SendPasswordReset.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Toast from "../../../components/UIComponents/tools/PublicToast";

const SendResetCodes = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [responseText, setResponseText] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [captchaToken, setCaptchaToken] = useState(null);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    showToast('Aufgrund von Wartungsarbeiten ist diese Funktion derzeit gesperrt. Bitte versuchen Sie es in 15 Minuten erneut.', 'info');
                    setTimeout(()=>{navigate('/sites/maintenance');}, 3000)
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    };

    useEffect(() => {
        checkMaintenanceMode();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            showToast('Bitte bestätigen Sie, dass Sie kein Roboter sind.', 'error');
            return;
        }

        const secKey = "57648393454";
        const url = "https://api.onesurvey.de/auth/send-password-reset";

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ secKey, email })
            });

            const text = await response.text();

            if (response.ok) {
                showToast('Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.', 'success');
            } else {
                if (text === "User not found"){
                    showToast('Benutzer nicht gefunden.', 'error');
                }
                else{
                    showToast('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.', 'error');
                }
                ;
            }
        } catch (error) {
            showToast('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.', 'error');
        }
    };

    const onCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div className="app-send-reset-password">
            <div className="page-container">
                <img
                    src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                    width="250px"
                    alt="OneSurvey Logo"
                />
                <h1>Ihr Passwort zurücksetzen</h1>
                <p>Bitte geben Sie Ihre E-Mail-Adresse an. Wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.</p>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">E-Mail-Adresse:</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="max.mustermann@domain.de"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <HCaptcha
                        sitekey="c3122632-8252-4e70-a38c-3f2857769e9c"
                        onVerify={onCaptchaVerify}
                    />
                    <button type="submit">Link senden</button>
                </form>
                <div className="spacer"></div>
                <a href="https://app.onesurvey.de">Zurück zur Anmeldung</a>
                <div className="spacer"></div>
                {responseText && <div className={`response ${responseStatus}`}>{responseText}</div>}
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
}

export default SendResetCodes;

