import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import './Dasboard.css';
import '../../../App.css';
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../../../components/UIComponents/screens/LoadingScreen";
import BarChart from "../../../components/UIComponents/icons/BarChart";
import PieChart from "../../../components/UIComponents/icons/PieChart";

const Dashboard = () => {
    const navigate = useNavigate();
    const [surveyCount, setSurveyCount] = useState(null);
    const [entryCount, setEntryCount] = useState(null);
    const [entryCountToday, setEntryCountToday] = useState(null);
    const [randomDataset, setRandomDataset] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [noData, setNoData] = useState(false);
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        fetchSurveyCount();
        fetchEntryCount();
        fetchEntryCountToday();
        fetchRandomDataset(); // Call für zufälligen Datensatz

        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }, []);

    const fetchSurveyCount = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/surveys/count`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const count = await response.text();
            setSurveyCount(count);
        } catch (error) {
            console.error("Error fetching survey count:", error);
            setError(true);
        }
    };

    const fetchEntryCount = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/surveys/entries/count`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const count = await response.text();
            setEntryCount(count);
        } catch (error) {
            console.error("Error fetching entry count:", error);
            setError(true);
        }
    };

    const fetchEntryCountToday = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/surveys/entries/count-today`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const count = await response.text();
            setEntryCountToday(count);
        } catch (error) {
            console.error("Error fetching today's entry count:", error);
            setError(true);
        }
    };

    const fetchRandomDataset = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/surveys/entries/get-random-dataset`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.ok){
                const data = await response.json();
                setRandomDataset(data);
            }
            else{
                const text = await response.text();
                if (text === "No data found."){
                    setNoData(true);
                }else{
                    setError(true);
                }
            }
        } catch (error) {
            console.error("Error fetching random dataset:", error);
            setError(true);
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <PieChart size={"80px"} color={"#3a3a3a"} fill={"none"}></PieChart>
                    <h1>Es sind noch keine Umfragen vorhanden.</h1>
                    <p>Es wurden keine Umfragen gefunden.</p>
                    <button className="btn btn-primary" onClick={()=>{navigate("/setup")}}>Jetzt Umfrage erstellen!</button>
                </div>
            </div>
        );
    }

    if (noData) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <BarChart size={"80px"} color={"#3a3a3a"} fill={"none"}></BarChart>
                    <h1>Noch keine Daten zum Anzeigen vorhanden.</h1>
                    <p>Es sind noch keine Daten zum anzeigen vorhanden. Erstellen Sie eine neue Kampagne und teilen Sie Ihre Umfrage mit Ihren Kunden!</p>
                    <button className="btn btn-primary" onClick={()=>{navigate("/campaigns")}}>Jetzt Daten sammeln!</button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/dashboard/`}> Dashboard</a>
                </span>
                <h1>Dashboard</h1>
                <div className="grid-dashboard">
                    <div className="card" id="dashboard-card" style={{flexGrow: 0}}>
                        <h1 id="dashboard-count">{surveyCount !== null ? surveyCount : "Laden..."}</h1>
                        <h3>Anzahl der Umfragen</h3>
                    </div>
                    <div className="card" id="dashboard-card" style={{flexGrow: 1}}>
                        <h1 id="dashboard-count">{entryCount !== null ? entryCount : "Laden..."}</h1>
                        <h3>Gesamtanzahl der Einträge in allen Umfragen.</h3>
                    </div>
                    <div className="card" id="dashboard-card">
                        <h1 id="dashboard-count">{entryCountToday !== null ? entryCountToday : "Laden..."}</h1>
                        <h3>Einträge heute in allen Umfragen.</h3>
                    </div>
                    <div className="card" id="dashboard-card" style={{flexGrow: 1}}>
                        <h1> &quot;{randomDataset ? randomDataset.data : "Laden..."}&quot;</h1>
                        <p>{randomDataset ? randomDataset.fieldTitle : "Laden..."}</p>
                        <h3>Zufälliger Eintrag</h3>
                        <a href={`/data/entry/${randomDataset.entryId}?origin=dashboard`}>Mehr erfahren</a>
                    </div>
                    <div className="card" id="dashboard-card" id="select-survey">
                        <h2>Jetzt noch mehr Details einsehen</h2>
                        <p>Wählen Sie eine Umfrage aus und bekommen Sie noch gezieltere Einblicke!</p>
                        <button onClick={()=>navigate('/panel/select-survey')} className="btn-primary-white">Umfrage auswählen</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
