import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../components/Header";
import LoadingScreen from "../../../../../components/UIComponents/screens/LoadingScreen";
import '../../../../../App.css';
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../../components/UIComponents/tools/Toast";

const CampaignMailTemplate = () => {
    const { campaignCode } = useParams();
    const accessToken = sessionStorage.getItem("OSACCESS");
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState({});
    const [survey, setSurvey] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        mailSubject: "",
        mailStart: "",
        mailContent: "",
        mailEnd: ""
    });
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) {
                    const text = await response.text();
                    console.log(text);
                    throw new Error(text);
                }

                const data = await response.json();
                setCampaign(data);
                setFormData({
                    mailSubject: data.mailSubject || "",
                    mailStart: data.mailStart || "",
                    mailContent: data.mailContent || "",
                    mailEnd: data.mailEnd || ""
                });
                fetchSurveyData(data.surveyId);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        };

        const fetchSurveyData = async (id) => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

                const data = await response.json();
                setSurvey({
                    ...data,
                    status: data.status || false,
                });
            } catch (error) {
                console.error(error);
            }
        };

        fetchCampaign();
    }, [campaignCode, accessToken]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}/mail-template`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error("Fehler beim Speichern des Templates");
            }
            showToast('Template erfolgreich abgeändert.', 'success')
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Speichern des Templates. Bitte laden Sie die Seite neu.', 'error')
        }
    };

    const sendTestMail = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}/mailing/send-test-mail`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Fehler beim Senden der Test-Email.");
            }
            const emailSendTo = await response.text();
            showToast(`Test-Email an ${emailSendTo} erfolgreich abgesendet.`, 'success')
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Senden der Test-Email.', 'error')
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"80px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Etwas ist schiefgelaufen</h1>
                    <p>Die Kampagne mit dem Code {campaignCode} wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/campaigns/`}> OneSurvey Campaigns &gt;</a>
                    <a href={`/campaigns/${campaign.code}`}> {campaign.title} &gt;</a>
                    <a href={`/campaigns/${campaign.code}/mail-template`}> E-Mail Template bearbeiten</a>
                </span>
                <h1>{campaign.title} - E-Mail Template bearbeiten</h1>
                <div className="survey-section action-card">
                    <h2>E-Mail Template</h2>
                    <form onSubmit={handleSubmit}>
                        <label className="form-label">
                            Betreff:
                            <div className="spacer4"></div>
                            <input
                                type="text"
                                name="mailSubject"
                                className="form-input"
                                value={formData.mailSubject}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <div className="spacer3"></div>
                        <label className="form-label">
                            Begrüßung:
                            <div className="spacer4"></div>
                            <textarea
                                style={{ height: "50px" }}
                                name="mailStart"
                                className="form-input textarea"
                                value={formData.mailStart}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <div className="spacer3"></div>
                        <label className="form-label">
                            Inhalt Ihrer E-Mail:
                            <div className="spacer4"></div>
                            <textarea
                                style={{ height: "200px" }}
                                name="mailContent"
                                className="form-input textarea"
                                value={formData.mailContent}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <div className="spacer3"></div>
                        <label className="form-label">
                            Ende Ihrer E-Mail:
                            <div className="spacer4"></div>
                            <textarea
                                style={{ height: "100px" }}
                                name="mailEnd"
                                className="form-input textarea"
                                value={formData.mailEnd}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <div className="spacer4"></div>
                        <button type="submit" className="btn btn-primary">Template abspeichern</button>
                        <button type="button" onClick={()=>sendTestMail()} className="btn btn-primary">E-Mail Test senden</button>
                        <button type="button" onClick={()=>navigate(`/campaigns/${campaign.code}`)} className="btn-secondary">Zurück zur Übersicht</button>
                    </form>
                </div>
                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}
            </div>
        </div>
    );
};

export default CampaignMailTemplate;
