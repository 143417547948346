import React, { useState, useEffect } from "react";
import LoadingScreen from "../../../../../components/UIComponents/screens/LoadingScreen";
import Header from "../../../../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../../components/UIComponents/tools/Toast";

const UpgradeSubscription = () => {
    const { subscriptionId } = useParams();
    const { productCode } = useParams();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [upgradeDetails, setUpgradeDetails] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [price, setEndPrice] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [authorizeError, setAuthorizeError] = useState(false);

    const fetchSubscription = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/${subscriptionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                setAuthorizeError(true);
                throw new Error("Fehler beim Abrufen der Produkte");
            }
            const data = await response.json();
            setSubscription(data);
        } catch (error) {

        }
    };

    const fetchUpgradeDetails = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/upgrade/${subscriptionId}/${productCode}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error("Fehler beim Abrufen der Upgrade Informationen");
            }
            const data = await response.json();
            setUpgradeDetails(data);
            setLoading(false);

            let formattedPrice = data.price.toFixed(2).replace('.', ',');
            setEndPrice(formattedPrice);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscription();
        fetchUpgradeDetails();
    }, [subscriptionId, productCode]);

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/upgrade/${subscriptionId}/${productCode}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {

                const text = await response.text();
                if (text === "No Subscription found."){
                    showToast("Fehler beim Abrufen des Abonnements. Bitte laden Sie die Seite erneut.", "error");
                }
                else if (text === "No Products found."){
                    showToast("Fehler beim Abrufen des OneCoding Shop. Bitte laden Sie die Seite erneut.", "error");
                }
                else if (text === "No Account found."){
                    showToast("Fehler beim Abrufen der Accountinformationen. Bitte laden Sie die Seite erneut.", "error");
                }
                else if (text === "No Upgrade to this Product is possible."){
                    showToast("Auf dieses Produkt ist derzeit kein Upgrade verfügbar. Bitte wählen Sie ein anderes Produkt aus.", "error");
                }
                else{
                    throw new Error("Error");
                }
            }
            else{
                const text = await response.text();
                navigate(`/myaccount/subscriptions/upgrade/checkout`);
            }
        } catch (error) {
        }
    }

    if (loading) {
        return <LoadingScreen/>;
    }

    if (authorizeError) {
        return <div>
            <Header />
            <div className="app-container">
                <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                <h1>Das Abonnement wurde nicht gefunden.</h1>
                <p>Das Abonnement konnte nicht abgerufen werden. Bitte überprüfen Sie Ihre Berechtigung oder versuchen Sie es später erneut.</p>
                <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                    Zurück zur Übersicht
                </button>
            </div>
        </div>;
    }

    if (error) {
        return <div>
            <Header />
            <div className="app-container">
                <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                <h1>Dieses Upgrade steht derzeit leider nicht zur Verfügung.</h1>
                <p>Dieses Upgrade steht derzeit leider nicht zur Verfügung. Bitte laden Sie diese Seite erneut oder probieren es später noch einmal.</p>
                <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                    Zurück zur Übersicht
                </button>
            </div>
        </div>;
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <h1>Upgrade Ihres Abonnements {subscription.code} ({subscription.title})</h1>
                <div className="action-card">
                    <h2>Allgemeine Details</h2>
                    <p><strong>Aktuelles Abonnement: </strong>{subscription.title} ({subscription.productCode})<br/>
                    Laufzeit noch bis zum {subscription.convertedEndDate}.</p>
                    <div className="spacer6"></div>
                    <p><strong>Neues Abonnement: </strong>{upgradeDetails.title} ({upgradeDetails.productCode})<br/>
                    <strong>Laufzeit: </strong>{upgradeDetails.convertedStartDate} - {upgradeDetails.convertedEndDate}<br/>
                    <strong>Gesamtpreis: </strong>{price} €<br/>
                    <strong>Verlängerung: </strong>
                        {upgradeDetails.autoRenew ? "Automatische Verlängerung" : "Das Abonnement läuft am Enddatum automatisch aus. Bitte beachten Sie, dass Sie nach Ablauf des Abonnements keinen Zugang mehr zur OneSurvey Cloud besitzen."}
                    </p>
                </div>
                <div className="spacer"></div>
                <div>
                    <h2>Upgrade bestellen</h2>
                    <p>Mit der Bestellung des Upgrades verlängern Sie Ihren Vertrag mit einem Abonnement nach obenstehenden Daten. Sie können nach einem Upgrade erst dieses Abonnement kündigen, sobald dieses angelaufen ist.</p>
                    <div className="spacer4"></div>
                    <h3>Bitte stimmen Sie unseren AGBs, Nutzungsbedingungen und Datenschutzbedingungen zu</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="order-aknowledge">
                            <div className="order-aknowledge-input-labelwrapper">
                                <label className="order-aknowledge-label" htmlFor="aknowledge">
                                    <p>
                                        Ich bestätige hiermit, dass ich mein bestehendes Abonnement ({subscription.title}) auf <strong>{upgradeDetails.title}</strong> kostenpflichtig upgraden möchte. <br />
                                        Der Gesamtpreis beträgt <strong>{price} €</strong>.<br />
                                        Mit dem Kauf stimme ich den <a href="https://onecoding.de/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> und den <a href="https://onecoding.de/agb" target="_blank" rel="noopener noreferrer">AGBs</a> der OneCoding Software Rottenbiller Schenk GbR zu.
                                    </p>
                                </label>
                            </div>
                            <div className="order-aknowledge-input-inputwrapper">
                                <input
                                    className="order-aknowledge-input"
                                    type="checkbox"
                                    name="aknowledge"
                                    required
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Jetzt kostenpflichtig kaufen</button>
                        <div className="spacer2"></div>
                    </form>
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                </div>
            </div>
            <div className="status-bar">
                <div className="status-bar-items">
                    <span>
                        <h2>Ihre Rechnung am {upgradeDetails.convertedStartDate}:</h2>
                    </span>
                    <span>
                        <p className="price-tag">{price} €</p>
                    </span>
                </div>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
}

export default UpgradeSubscription;