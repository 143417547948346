import './Icon.css';

const SelectIcon = ({size, fill, color}) => (

    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        id="select"
        alt="select"
        className="icon">
        >
        <path
            d="M6 12H18M18 12L13 7M18 12L13 17"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>


)

export default SelectIcon