import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../SurveyFields.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import Toast from "../../../../../../components/UIComponents/tools/Toast";
import Header from "../../../../../../components/Header";
import DragDrop from "../../../../../../components/UIComponents/icons/DragDrop";

const SurveyFields_Rearrange = () => {
    const { surveyCode } = useParams();
    const navigate = useNavigate();
    const [fields, setFields] = useState([]);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });

    useEffect(() => {
        fetchSurveyId();
    }, []);

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
            await fetchSurveyFields(data.id);
            await fetchSurveyData(data.id);
        } catch (error) {
            showToast('Fehler beim Abrufen der Felder. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Abrufen der Umfrage-Daten. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const fetchSurveyFields = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}/fields`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFields(data);
        } catch (error) {
            console.log("Fehler beim Abrufen der Felder:", error);
        }
    };

    const removeField = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            showToast(`Frage ${fieldId} erfolgreich gelöscht.`, 'success');
            fetchSurveyFields(surveyId);
        } catch (error) {
            showToast(`Fehler beim Löschen des Feldes ${fieldId}`, 'error');
            console.log("Fehler beim Löschen des Feldes:", error);
        }
    };

    // Drag & Drop: Aktualisiere das Array der Felder nach dem Drag
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(fields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFields(items);  // Update the local state
    };

    // API-Aufruf zum Speichern der neuen Reihenfolge
    const saveFieldOrder = async () => {
        try {
            const fieldsWithPositions = fields.map((field, index) => ({
                id: field.id,
                position: index + 1
            }));

            console.log(fieldsWithPositions);

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/fields/update-positions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(fieldsWithPositions),
            });

            if (response.ok) {
                showToast('Die Reihenfolge der Felder wurde erfolgreich gespeichert.', 'success');
            } else {
                const text = await response.json();
                console.log(text)
                showToast(text, 'error');
            }
        } catch (error) {
            showToast('Fehler beim Speichern der Reihenfolge. Bitte versuchen Sie es erneut.', 'error');
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };
    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (

        <div>
            <Header />
            <div className="survey-fields-container">
                <span className="app-breadcrumbs">
                    <a href='/mysurveys'>Meine Umfragen &gt; </a>
                    <a href={`/mysurveys/${surveyCode}`}>{survey.title} &gt; </a>
                    <a href={`/mysurveys/${surveyCode}/fields`}>Felder & Fragen &gt; </a>
                    <a href={`/mysurveys/${surveyCode}/fields/positions`}>Felder anordnen</a>
                </span>
                <h1>Felder anordnen</h1>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="fields">
                        {(provided) => (
                            <div className="list" {...provided.droppableProps} ref={provided.innerRef}>
                                {fields.map((field, index) => (
                                    <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                className="list-item"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div style={{display: "flex", flexDirection: "row", gap: "20px", alignItems: "center"}}>
                                                    <span>
                                                        <DragDrop size={"30px"} fill={"none"} color={"grey"}></DragDrop>
                                                    </span>
                                                    <div className="field-description">
                                                        <p>{field.title}</p>
                                                        <p><span className="secondaryText">Position: {index + 1}</span></p>
                                                    </div>
                                                </div>


                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <button onClick={saveFieldOrder} className="btn btn-primary">Reihenfolge speichern</button>
                <button onClick={()=>navigate(`/mysurveys/${surveyCode}/fields`)} className="btn-secondary">Zurück zur Übersicht</button>
                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}
            </div>
        </div>
    );
};

export default SurveyFields_Rearrange;
