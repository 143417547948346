import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, Filler, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, BarElement } from 'chart.js';
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import './SurveyDashboard.css';
import FieldComponent from "./FieldComponents/FieldComponent";
import SelectIcon from "../../../../components/UIComponents/icons/Select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Toast from "../../../../components/UIComponents/tools/Toast";
import campaigns from "../../Campaigns/Campaigns";

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, BarElement, Filler);

const SurveyDashboard = () => {
    const navigate = useNavigate();
    const { surveyCode } = useParams();
    const [loading, setLoading] = useState(true);
    const [survey, setSurvey] = useState(
    { title: "",
                description: "",
                isSurveyShared: false,
                originSharedAccount_Email: "",
                originSharedAccount_Firstname: "",
                originSharedAccount_Lastname: ""
            });

    const [fields, setFields] = useState([]);
    const [entries, setEntries] = useState([]);
    const [surveyId, setSurveyId] = useState(null);
    const [error, setError] = useState(false);
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [totalEntries, setTotalEntries] = useState(0);
    const [entriesToday, setEntriesToday] = useState(0);
    const [surveyVisits, setSurveyVisits] = useState(0);
    const [surveyDuration, setDuration] = useState(0);
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [startDate, setStartDate] = useState("");
    const [UTCStart, setUTCStart] = useState();
    const [UTCEnd, setUTCEnd] = useState();
    const [endDate, setEndDate] = useState("")
    const [campaign, setCampaign] = useState("");
    const [campaignData, setCampaignData] = useState({});
    const [filter, setFilter] = useState("");
    const [isindividualFilter, setIndividualFilter] = useState(false);
    const [isCampaignFilter, setCampaignFilter] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    useEffect(() => {
        const init = async () => {
            await authorizationCheck();
            await SubscriptionCheck();
            await checkCampaign();
            await fetchSurveyId();
            setLoading(false);
        };
        init();
    }, [startDate, endDate, campaign]);

    const checkCampaign = async () => {
        const campaignCode = params.get('campaign');
        if (campaignCode != null){
            let isValid = false;
            try{
                const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/validate-campaign/${campaignCode}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    setCampaign("");
                }
                else{
                    setCampaign(campaignCode);
                    isValid = true;
                }

            }catch (error){
                console.log(error);
            }

            if (isValid){
                try{
                    const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/campaign/${campaignCode}/statistics/visited`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }catch (error){
                    console.log(error);
                }

                try{
                    const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`,
                        }
                    });

                    if (response.ok){
                        const data = await response.json();
                        setCampaignData(data);
                        setCampaignFilter(true);
                    }

                }catch (error){
                    console.log(error);
                }
            }
        }
    }

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate/origin-dashboard`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-ID");

            const data = await response.json();
            setSurveyId(data.id);
            setSurvey(data);

            fetchChartData(data.id);

            await Promise.all([
                fetchTotalEntries(data.id),
                fetchEntriesToday(data.id),
                fetchSurveyVisits(data.id),
                fetchAverageDuration(data.id),
                fetchSurveyFields(data.id),
                fetchSurveyEntries(data.id)
            ]);

        } catch (error) {
            setError(true);
        }
    };

    const fetchChartData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/entries/chart-data?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const result = await response.json();
            const labels = result.map(item => new Date(item.date).toLocaleDateString());
            const data = result.map(item => item.count);

            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: 'Anzahl der Einträge nach Datum',
                        data: data,
                        fill: false,
                        backgroundColor: "rgba(25,88,229,0.21)",
                        borderColor: '#1958e5',
                        tension: 0.4,
                        pointBackgroundColor: '#1958e5',
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointHoverBorderWidth: 10
                    }
                ],
            });
        } catch (error) {
        }
    };

    const fetchTotalEntries = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/entries/count?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Gesamtanzahl der Einträge");

            const result = await response.text();
            setTotalEntries(result);
        } catch (error) {
            setError(true);
        }
    };

    const fetchEntriesToday = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/entries/count-today`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der heutigen Einträge");

            const result = await response.text();
            setEntriesToday(result);
        } catch (error) {
            setError(true);
        }
    };

    const fetchSurveyVisits = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/statistics/visited`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfragebesuche");

            const result = await response.text();
            setSurveyVisits(result);
        } catch (error) {
            setError(true);
        }
    };

    const fetchAverageDuration = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/statistics/duration?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok)
                throw new Error("Fehler beim Abrufen der durchschnittlichen Bearbeitungsdauer der Umfrage.");

            const result = await response.text();
            console.log(result);
            setDuration(result);
        } catch (error) {

        }
    };

    const fetchSurveyFields = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/dashboard/survey/${id}/fields`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Felder");

            const data = await response.json();
            setFields(data);

        } catch (error) {
            setError(true);
        }
    };

    const fetchSurveyEntries = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/data/survey/${id}/entries?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Einträge");

            const data = await response.json();
            setEntries(data);

        } catch (error) {
            setError(true);
        }
    };

    const formatDate = (date) => {
        let day = date.getDate();
        day = day < 10 ? '0' + day : day;
        let month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : month;
        let year = date.getFullYear();

        const currentYear = new Date().getFullYear();
        if (year > currentYear) {
            year = new Date().getFullYear();
            showToast('Daten in diesem Zeitraum stehen derzeit noch nicht zur Verfügung.', 'error');
        }
        return `${year}-${month}-${day}`;
    }

    const handleFilterChange = (e) => {
        const today = new Date();
        let startDate, endDate;

        setIndividualFilter(false);

        switch (e) {
            case "today":
                startDate = new Date(today.setHours(0, 0, 0, 0));
                endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + 1);
                endDate.setHours(0, 0, 0, 0);
                break;

            case "this-week":
                const firstDayOfWeek = today.getDate() - today.getDay() + 1;
                startDate = new Date(today.setDate(firstDayOfWeek));
                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 7);
                break;

            case "last-week":
                const lastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 6));
                startDate = new Date(lastWeek);
                endDate = new Date(lastWeek.setDate(lastWeek.getDate() + 7));
                break;

            case "this-month":
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;

            case "last-month":
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;

            case "this-year":
                startDate = new Date(today.getFullYear(), 0, 1);
                endDate = new Date(today.getFullYear(), 11, 31);
                break;

            case "individual":
                setIndividualFilter(true);
                startDate = "";
                endDate = "";
                break;

            default:
                startDate = "";
                endDate = "";
                break;
        }

        // Start- und Enddatum in UTC-Format umwandeln
        setStartDate(startDate ? formatDate(startDate) : "");
        setEndDate(endDate ? formatDate(endDate) : "");
        setFilter(e); // Filteroption setzen
    };

    const handleStartDateChange = (date) => {
        setStartDate(formatDate(date));
        setUTCStart(date)
        if (date > UTCEnd){
            showToast('Startdatum später als Enddatum.', 'error');
            setStartDate(endDate);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(formatDate(date));
        setUTCEnd(date)
        if (date < UTCStart){
            showToast('Startdatum später als Enddatum.', 'error');
            setEndDate(startDate);
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code {surveyId} wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/dashboard/`}> Dashboard &gt;</a>
                    <a href={`/dashboard/${surveyCode}`}> "{survey.title}"</a>
                </span>
                <h1>"{survey.title}" - Dashboard</h1>
                <span className="secondaryText">{survey.isSurveyShared ? <p>Geteilt von <a href={`mailto:${survey.originSharedAccount_Email}`}>{survey.originSharedAccount_Fullname}</a><br/></p> : ""}</span>
                <div className="grid-dashboard">
                    <div className="" id="dashboard-card" style={{flexGrow: 1}}>
                        <div className="filter-box">
                            <div>
                                <form>
                                    <select
                                        className="dropdown"
                                        id="filter"
                                        name="survey"
                                        style={{ width: "auto"}}
                                        onChange={(e) => handleFilterChange(e.target.value)}
                                        required
                                    >
                                        <option value="all">
                                            Gesamter Zeitraum
                                        </option>
                                        <option value="today">
                                            Heute
                                        </option>
                                        <option value="this-week">
                                            Diese Woche
                                        </option>
                                        <option value="last-week">
                                            Vergangene Woche
                                        </option>
                                        <option value="this-month">
                                            Dieser Monat
                                        </option>
                                        <option value="last-month">
                                            Vergangener Monat
                                        </option>
                                        <option value="this-year">
                                            Dieses Jahr
                                        </option>
                                        <option value="individual">
                                            Individuell
                                        </option>
                                    </select>
                                </form>
                            </div>
                            <div>
                                {
                                    isindividualFilter ?
                                        <div>
                                            <form>
                                                <div className="date-picker-wrapper">
                                                    <div className="date-picker-container">
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleStartDateChange}
                                                            dateFormat="dd.MM.yyyy"
                                                            placeholderText="Startdatum"
                                                            className="date-picker-input"
                                                        />
                                                    </div>
                                                    <div className="date-picker-container">
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={handleEndDateChange}
                                                            dateFormat="dd.MM.yyyy"
                                                            placeholderText="Enddatum"
                                                            className="date-picker-input"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        :
                                        <div>
                                            <p></p>
                                        </div>
                                }
                            </div>
                            <div>
                                {
                                    isCampaignFilter ?
                                        <div>
                                            <p>Gefiltert nach: Kampagne {campaignData.title} &nbsp;(<a href={`/dashboard/${surveyCode}`}>Filter entfernen</a>)</p>
                                        </div>
                                        :
                                        <p></p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-dashboard">
                    <div className="card" id="chart-container">
                        <Line id="chart" data={chartData} options={options} />
                    </div>
                    <div className="card" id="dashboard-card">
                        <h1 id="dashboard-count">{surveyVisits}</h1>
                        <p>Mal wurde diese Umfrage geöffnet</p>
                        <div className="divider"></div>
                        <h1 id="dashboard-count">{totalEntries}</h1>
                        <p>Einträge insgesamt</p>
                        <div className="divider"></div>
                        <h1 id="dashboard-count">{surveyDuration}</h1>
                        <p>Durchschnittliche Bearbeitungszeit</p>
                    </div>
                </div>
                <div className="spacer"></div>
                <h1>Daten nach Feldern</h1>
                <div className="masonry-wrapper">
                    <div className="masonry-grid" style={{flexGrow: 1}}>
                        {fields.map((field) => (
                            <FieldComponent key={field.id} field={field} startDate={startDate} endDate={endDate} campaign={campaign}/>
                        ))}
                    </div>
                </div>
                <div className="spacer"></div>
                <h1>Alle Einträge</h1>
                <p>Wählen Sie einen Eintrag aus um weitere Details zu sehen.</p>
                <div className="spacer"></div>
                <div>
                    {
                        (entries.length > 0) ?
                            <div className="list" id="entry-list">
                                {entries.map((entry) => (
                                    <a href={`/data/entry/${entry.id}`} style={{textDecoration: "none"}}>
                                        <div className="list-item" id={entry.id}>
                                            <div id="item-description">
                                                <h3>Eintrag-ID: {entry.id}</h3>
                                                <p>{entry.convertedCreationTime} Uhr - Kampagne: {entry.campaignTitle || "Keine Kampagne hinterlegt."}</p>
                                            </div>
                                            <div id="item-controls">
                                                <SelectIcon size={"50px"} fill={"none"} color={"currentColor"}/>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                            :
                            <div>
                                <p style={{color: "gray"}}>Leider sind keine Einträge vorhanden.</p>
                            </div>
                    }
                </div>

                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}

            </div>
        </div>
    );
};

export default SurveyDashboard;