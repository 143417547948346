import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import '../../../../App.css';
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import SiteIcon from "../../../../components/UIComponents/icons/SiteView";
import PageIcon from "../../../../components/UIComponents/icons/PageView";
import './Layout.css';
import Toast from "../../../../components/UIComponents/tools/Toast";

const Setup_SurveyLayout = () => {
    let { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        authorizationCheck();
        fetchSurveyId();
        SubscriptionCheck();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                showToast('Keine aktive OneSurvey-Subscription', 'error');
                throw new Error("Keine aktive OneSurvey-Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.ok){
                console.log("Berechtigungsprüfung erfolgreich.");
            }
            else{
                setError(true);
                showToast('Keine Berechtigung zur Bearbeitung dieser Umfrage. Bitte laden Sie die Seite neu.', 'error');
                console.log("Keine Berechtigung zur Bearbeitung dieser Umfrage.");
            }
        }
        catch (error){
            setError(true);
            console.log(error);
        }
    }

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            showToast('Keine Berechtigung zur Bearbeitung dieser Umfrage. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const siteLayout = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/edit/layout/SITE`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok){
                showToast('Das Layout wurde erfolgreich für die Darstellung auf einer Seite gespeichert.', 'success');
                setTimeout(() => navigate(`/setup/${surveyCode}/publish`), 1500);
            }
        } catch (error) {
            console.log("Fehler beim Veröffentlichen:", error);
            showToast('Fehler beim Speichern des Layouts. Bitte erneut versuchen.', 'error');
        }
    }

    const pageLayout = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/edit/layout/PAGE`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok){
                showToast('Das Layout wurde erfolgreich für die Darstellung pro Seite gespeichert.', 'success');
                setTimeout(() => navigate(`/setup/${surveyCode}/publish`), 1500);
            }
        } catch (error) {
            console.log("Fehler beim Veröffentlichen:", error);
            showToast('Fehler beim Speichern des Layouts. Bitte erneut versuchen.', 'error');
        }
    }

    if (error){
        return(
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Zugriff verweigert</h1>
                    <p>Die Umfrage mit dem Code wurde nicht gefunden, oder Sie haben keine Berechtigung, sie zu bearbeiten.</p>
                </div>
            </div>
        )
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header/>
            <div className="layout-app-container">
                <h1>Bitte wählen Sie ein Layout</h1>
                <p>Wählen Sie ein Layout, um Ihre Umfrage anzuzeigen: Alle Fragen auf einer Seite oder Fragen einzeln pro Seite.</p>
                <div className="layout-options">
                    <div className="layout-tile" onClick={siteLayout}>
                        <SiteIcon size={"60px"} color={"#a4a4a4"} fill={"none"}/>
                        <h2>Alle Fragen auf einer Seite</h2>
                    </div>
                    <div className="layout-tile" onClick={pageLayout}>
                        <PageIcon size={"60px"} color={"#a4a4a4"} fill={"none"}/>
                        <h2>Fragen einzeln pro Seite</h2>
                    </div>
                </div>
                <p>{message}</p>
                <div className="spacer"></div>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    )
}

export default Setup_SurveyLayout;
