import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import "./MySurvey.css";
import Toast from "../../../../components/UIComponents/tools/Toast";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";

const MySurvey = () => {
    const { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [surveyTitle, setSurveyTitle] = useState(null);
    const [surveyPublicCode, setSurveyCode] = useState(null);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        const init = async () => {
            await authorizationCheck();
            await SubscriptionCheck();
            await fetchSurveyId();
        };
        init();
    }, [surveyCode]);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                setError(true);
            } else {
                setSurveyCode(surveyCode.toUpperCase());
                console.log("Berechtigungsprüfung erfolgreich.");
            }
        } catch (error) {
            setError(true);
            console.error("Fehler bei der Berechtigungsprüfung:", error);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-ID");

            const data = await response.json();
            setSurveyId(data.id);
            fetchSurveyData(data.id);
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
            setSurveyTitle(data.title);
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Abrufen der Umfrage-Daten");
        }
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        if (isChecked){
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/delete`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.ok) {
                    setMessage("Umfrage erfolgreich gelöscht.");
                    showToast('Umfrage erfolgreich gelöscht', 'success')
                    setTimeout(()=>{navigate('/mysurveys');}, 1000)
                } else {
                    throw new Error("Fehler beim Löschen der Umfrage.");
                    showToast('Fehler beim Löschen der Umfrage', 'error')
                }
            } catch (error) {
                console.error(error);
                setMessage("Fehler beim Löschen der Umfrage.");
                showToast('Fehler beim Löschen der Umfrage', 'error')
            }
        }
        else {
            showToast('Bitte bestätigen Sie, dass Sie diese Umfrage löschen möchten.', 'info')
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Umfrage mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="app-container my-survey-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt; </a>
                    <a href='/mysurveys'>Meine Umfragen &gt; </a>
                    <a href={`/mysurveys/${surveyCode}`}>{surveyTitle} </a>
                </span>
                <h1 className="my-survey-title">{surveyTitle}</h1>
                <span className="secondaryText">{survey.isSurveyShared ? <p>Geteilt von <a href={`mailto:${survey.originSharedAccount_Email}`}>{survey.originSharedAccount_Fullname}</a><br/></p> : ""}</span>
                <p className="my-survey-description">{survey.description}
                </p>

                <div className="survey-section action-card">
                    <h2>Allgemeine Informationen</h2>
                    <p><strong>Umfragecode:</strong> <a href={`https://app.onesurvey.de/s/${surveyPublicCode}`}>{surveyPublicCode}</a></p>
                    <p><strong>Status:</strong> {survey.status ? "Aktiv (veröffentlicht)" : "Inaktiv (als Entwurf gespeichert)"}</p>
                    <p><strong>Link zu Ihrer Webseite:</strong> {survey.linkHome || "Kein Link festgelegt."}</p>
                    <p><strong>Link zu Ihrem Impressum:</strong> {survey.linkImpressum  || "Kein Link festgelegt."}</p>
                    <p><strong>Link zur Datenschutzerklärung:</strong> {survey.linkPrivacy || "Kein Link festgelegt."}</p>
                    <div className="spacer"></div>
                    <a href={`/mysurveys/${surveyCode}/edit`} className="btn btn-primary">Bearbeiten</a>
                </div>

                <div className="survey-section action-card">
                    <h2>Felder & Fragen</h2>
                    <p>Verwalten Sie die Felder und Fragen Ihrer Umfrage.</p>
                    <a href={`/mysurveys/${surveyCode}/fields`} className="btn btn-primary">Felder & Fragen</a>
                </div>

                <div className="survey-section action-card">
                    <h2>Design-Einstellungen</h2>
                    <p>Verwalten Sie die Designeinstellungen Ihrer Umfrage.</p>
                    <a href={`/mysurveys/${surveyCode}/design-settings`} className="btn btn-primary">Designeinstellungen</a>
                </div>

                <div className="survey-section action-card">
                    <h2>Umfrage löschen</h2>
                    <p>Warnung: Das Löschen dieser Umfrage ist dauerhaft und kann nicht rückgängig gemacht werden.</p>
                    <div className="warn-container">
                        <p id="warn-container-badge">Wichtige Information</p>
                        <h3 className="warn-container-title">Achtung! Alle Einträge und Datensätze werden ebenfalls gelöscht!</h3>
                        <p id="warn-container-description">
                            Mit dem Löschen dieser Umfrage werden alle damit verbundenen Daten unwiderruflich entfernt. Dies umfasst sämtliche Einträge, Antworten und persönlichen Informationen der Teilnehmer. Stellen Sie sicher, dass Sie eine Sicherungskopie wichtiger Daten erstellt haben, bevor Sie fortfahren.
                        </p>
                    </div>
                    <form onSubmit={handleDelete}>
                        <label className="checkbox-label">
                            <input
                                className="checkbox-input"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            Ich bestätige, dass ich diese Umfrage, sowie alle zugehörigen Daten, inkl. Antworten und Einträge unwiderruflich entfernen möchte.
                        </label>
                        <div className="spacer3"></div>
                        {message && <p className="delete-message">{message}</p>}
                        <button type="submit" className="btn-danger">Umfrage löschen</button>
                    </form>
                </div>
                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}
                <button className="btn-primary" onClick={()=>navigate(`/mysurveys`)}>Zurück zur Übersicht</button>
            </div>
        </div>
    );
};

export default MySurvey;
