import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import '../EditField/EditFields.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";

const ListOptions = () => {
    const { surveyCode } = useParams();
    const { fieldId} = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [options, setOptions] = useState([]);
    const [currentFieldOptions, setCurrentFieldOptions] = useState(null);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [fieldError, setFieldError] = useState(false);
    const [fieldSettings, setFieldSettings] = useState({
        position: 0,
        title: 'TEST',
        description: '',
        hintText: ''
    });

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchFieldOptions(fieldId);
        fetchFieldData(fieldId);
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung des Feldes erfolgreich.");
            } else {
                setFieldError(true);
                console.log("Keine Berechtigung, dieses Feld zu bearbeiten.");
            }
        } catch (error) {
            setFieldError(true);
            console.log(error);
        }
    };

    const fetchFieldOptions = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setOptions(data);
            if (response.ok){
                setCurrentFieldOptions(true);
            }
        } catch (error) {
            console.log("Fehler beim Abrufen der Optionen:", error);
        }
    };

    const fetchFieldData = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldSettings(data);
        } catch (error) {
            console.log("Fehler beim Abrufen des Feldes:", error);
        }
    };

    const removeOption = async (optionId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchFieldOptions(fieldId);
        } catch (error) {
            console.log("Fehler beim Löschen der Option:", error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (fieldError) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Das Feld wurde nicht gefunden oder Sie haben keine Berechtigung, dieses zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    return (
        <div>
            <Header />
            <div className="edit-fields-container">
                <h1>Optionen anzeigen und bearbeiten</h1>
                <p>Bearbeiten Sie die Optionen dieses Feldes.</p>
                <div className="option-list" id="get-options-item-list">
                    {options.map((option) => (
                        <div className="field" id="fielditem-second" key={option.id}>
                            <p>
                                {option.title}
                            </p>
                            <div className="controls">
                                <span onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options/${option.id}/edit`)}><EditIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="add-items">
                    <button className="btn-primary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options/add`)}>Neue Option hinzufügen</button>
                </div>
                <p>{message}</p>
                <button className="btn-secondary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/edit`)}>Zurück</button>
            </div>
        </div>
    );

}

export default ListOptions