import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import './Campaign.css';
import '../../../../App.css';
import loadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import EmailInput from "../../../../components/UIComponents/tools/EmailInput";
import Toast from "../../../../components/UIComponents/tools/Toast";

const Campaign = () => {
    const { campaignCode } = useParams();
    const accessToken = sessionStorage.getItem("OSACCESS");
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState({});
    const [survey, setSurvey] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    const fetchCampaigns = async () => {
        try{
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok){
                const text = await response.text();
                console.log(text);
                throw new Error(text);
            }
            const data = await response.json();
            setCampaign(data);
            fetchSurveyData(data.surveyId)
            setLoading(false);

        }catch (error){
            setLoading(false);
            setError(true);
        }
    }

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false,
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(()=>{
        fetchCampaigns();
    }, [])

    const handleEmailsChange = (newEmails) => {
        setEmailList(newEmails);
        console.log(newEmails)
    };

    const handleSendMail = async (e) => {
        e.preventDefault();

        if (emailList.length === 0){
            showToast('Bitte geben Sie eine E-Mail-Adresse ein.', 'error')
        }
        else{
            const data = {
                emails: emailList
            }

            try {
                const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}/mailing/send-prod-mail`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Senden der Test-Email.");
                }
                const emailSendTo = await response.text();
                showToast(`E-Mails erfolgreich abgesendet.`, 'success')
            } catch (error) {
                console.error(error);
                showToast('Fehler beim Senden der Test-Email.', 'error')
            }
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    if (error){
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"80px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Etwas ist schiefgelaufen</h1>
                    <p>Die Kampagne mit dem Code {campaignCode} wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (loading){
        return (
            <LoadingScreen></LoadingScreen>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/campaigns/`}> OneSurvey Campaigns &gt;</a>
                    <a href={`/campaigns/${campaign.code}`}> {campaign.title}</a>
                </span>
                <h1>Kampagne - {campaign.title}</h1>
                <div className="survey-section action-card">
                    <h2>Allgemeine Informationen</h2>
                    <p><strong>Kampagnentitel:</strong> {campaign.title}</p>
                    <p><strong>Kampagnen-Code:</strong> {campaign.code}</p>
                    <p><strong>Umfrage:</strong> <a style={{textDecoration: "none"}} href={`/s/${survey.code}`}>{survey.title}</a></p>
                    <a href={`/campaigns/${campaignCode}/edit`} className="btn btn-primary">Bearbeiten</a>
                </div>
                <div className="survey-section action-card">
                    <h2>E-Mail Template</h2>
                    <p><strong>Betreff:</strong> {campaign.mailSubject}</p>
                    <p><strong>Inhalt:</strong>
                        <br/><br/>
                        {campaign.mailStart.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br/>
                            </span>
                        ))}
                        <br/>
                        {campaign.mailContent.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br/>
                            </span>
                        ))}
                        <br/>
                        {campaign.mailEnd.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br/>
                            </span>
                        ))}
                    </p>
                    <div className="spacer"></div>
                    <a href={`/campaigns/${campaignCode}/mail-template`} className="btn btn-primary">E-Mail Template anpassen</a>
                </div>
                <div className="survey-section action-card">
                    <h2>Kampagne einfach per E-Mail teilen und Kunden direkt zur Teilnahme an Ihrer Umfrage einladen!</h2>
                    <p>Versenden Sie Ihre Umfrage direkt per E-Mail an Ihre Kunden. Die Empfänger erhalten eine Einladung zur Teilnahme an der Umfrage, inklusive eines Links zur bequemen Teilnahme.</p>
                    <form onSubmit={handleSendMail}>
                        <label className="form-label">
                            E-Mail:
                            <div className="spacer4"></div>
                            <EmailInput onEmailsChange={handleEmailsChange}/>
                        </label>
                        <div className="spacer4"></div>
                        <button type="submit" className="btn btn-primary">Einladungsemail senden</button>
                    </form>
                </div>
                <a href={`/campaigns`} className="btn btn-primary">Zurück zur Übersicht</a>
                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}
            </div>
        </div>
    );


}
export default Campaign;