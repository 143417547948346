import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import './AddField.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import Check from "../../../../../../components/UIComponents/icons/Check";
import CheckIcon from "../../../../../../components/UIComponents/icons/Check";
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";

const AddFieldCheckout = () => {
    const { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(false);

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="add-fields-container">
                <CheckIcon size={"60px"} fill={"none"} color={"yellowgreen"} />
                <h1>Ihr Feld wurde erfolgreich hinzugefügt!</h1>
                <p>Ihre Frage wurde erfolgreich zu Ihrer Umfrage hinzugefügt.</p>
                <button className="btn-primary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/add`)}>Weiteres Feld hinzufügen</button>
                <button className="btn-secondary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields`)}>Zurück zur Übersicht</button>
            </div>
        </div>
    );

}

export default AddFieldCheckout