import React, {useState} from "react";
import './CustomHeader.css';

const CustomHeader = ({logoUrl, headerBackground}) => {

    const [logo, setLogo] = useState(logoUrl || '/headerlogo-ocs-white.png');
    const [headerBG, setHeaderBG] = useState(headerBackground || '#1958e5');

    return(
        <div className="custom-header" style={{ background: headerBG}}>
            <div className="wrapper">
                <img className="custom-header-logo" alt="Logo" src={logo}></img>
            </div>
        </div>
    );




}
export default CustomHeader;