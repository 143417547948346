import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/PublicFooter";
import '../../App.css';
import WarningIcon from "../../components/UIComponents/icons/Warning";

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <WarningIcon size={"80px"} color={"#3a3a3a"} fill={"none"}></WarningIcon>
                <h1>404 Inhalt oder Seite nicht gefunden!</h1>
                <p>Die gewünschte Seite oder der Inhalt wurde leider nicht gefunden.</p>
                <button type="button" onClick={()=> navigate('/')}>zurück zur Startseite</button><br/>
            </div>
            <Footer></Footer>
        </div>
    );

}

export default NotFound;