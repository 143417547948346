import React, {useEffect, useState} from "react";
import './Footer.css';
import '../App.css';
import {useNavigate} from "react-router-dom";

const Footer = () => {

    return (
        <div className="app-footer">
            <div className="footer-container">
                <div className="footer-columns">
                    <div className="footer-column">
                        <h4>OneSurvey WebApp</h4>
                        <ul>
                            <li><a href="/login">Anmelden</a></li>
                            <li><a href="/create-account">Account erstellen</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Service</h4>
                        <ul>
                            <li><a href="https://onesurvey.de/kontakt">Kontakt</a></li>
                            <li><a href="https://onesurvey.de/support">Support & Hilfebereich</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Unternehmen</h4>
                        <ul>
                            <li><a href="https://onecoding.de/ueber-uns/">Über uns</a></li>
                            <li><a href="https://onecoding.de/agb">AGB</a></li>
                            <li><a href="/datenschutz">Datenschutz</a></li>
                            <li><a href="/impressum">Impressum</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4></h4>
                        <div className="footer-logo">
                            <a href="https://onecoding.de"><img src="/headerlogo-ocs-white.png" alt="OneCoding Software" /></a>
                        </div>
                    </div>
                </div>
                <p id="copyright">© 2024 OneCoding Software Rottenbiller Schenk GbR</p>
            </div>

        </div>
    );
}

export default Footer;
