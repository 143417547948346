import './Icon.css';

const BackIcon = ({size, fill, color}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        id="back"
        alt="back"
        className="icon">
        >
        <path
            d="M5 12H19M5 12L11 6M5 12L11 18"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
)

export default BackIcon