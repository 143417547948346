import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../components/UIComponents/tools/Toast";

const EditPersonalData = () => {
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        plz: "",
        country: "",
        username: "",
        company: false,
        companyName: ""
    });

    useEffect(() => {
        loadUserProfile();
    }, []);

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    id: account.id,
                    firstname: account.firstname,
                    lastname: account.lastname,
                    email: account.email,
                    phone: account.phone,
                    street: account.street,
                    city: account.city,
                    plz: account.plz,
                    country: account.country,
                    username: account.username,
                });
            } else {
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            showToast('Fehler beim Abrufen der Profilinformationen', 'error');
            console.error("Failed to load user profile", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: userInfo.phone
            };

            const response = await fetch(`https://api.onesurvey.de/v1/account/edit/personal-information`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                const text = await response.text();
                if (text === "E-Mail already used") {
                    showToast(`Die E-Mail-Adresse ${userInfo.email} wird bereits von einem anderen Konto verwendet.`, 'error');
                } else if (text === "Username already used") {
                    showToast(`Der Benutzername ${userInfo.username} wird bereits von einem anderen Konto verwendet.`, 'error');
                } else if (text === "E-Mail is a necessary field"){
                    showToast(`Bitte geben Sie eine E-Mail-Adresse an.`, 'error');
                } else {
                    showToast(text, 'error');
                }
                throw new Error('Fehler beim Ändern der Daten');
            }

            showToast('Persönliche Informationen erfolgreich abgeändert', 'success');
            setTimeout(()=>{navigate('/myaccount')}, 1000)
        } catch (error) {
            setError(error);
            console.log(`Error ${error}`);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };


    return (
        <div className="account-edit-page">
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/personal-information`}> Persönliche Informationen anpassen</a>
                </span>
                <h1>Persönliche Informationen anpassen</h1>
                <p>Auf dieser Seite können Sie Ihre Benutzerdaten anpassen.</p>
                <form onSubmit={handleSubmit} className="form-edit">
                    <div className="form-group">
                        <label>Vorname</label>
                        <input
                            type="text"
                            value={userInfo.firstname}
                            name="firstname"
                            placeholder="Max"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Nachname</label>
                        <input
                            type="text"
                            value={userInfo.lastname}
                            name="lastname"
                            placeholder="Mustermann"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>E-Mail</label>
                        <input
                            type="email"
                            value={userInfo.email}
                            name="email"
                            placeholder="example@example.com"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Telefon-Nr:</label>
                        <input
                            type="phone"
                            value={userInfo.phone}
                            name="phone"
                            placeholder="+49 123456789"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <button type="submit" className="btn-primary">Daten ändern</button>
                        <button className="btn-secondary" onClick={()=>navigate('/myaccount')}>Abbrechen</button>
                        {message && <p className="form-message">{message}</p>}
                    </div>
                </form>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default EditPersonalData;
