import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import "../../../../App.css";
import "./ProductPage.css"
import CheckIcon from "../../../../components/UIComponents/icons/Check";

const ProductPageOrder = () => {
    const { productCode } = useParams();
    const [product, setProduct] = useState({
        id: 0,
        code: "",
        title: "",
        description: "",
        price: 0.00,
        status: false,
        autoRenew: false,
        type: "",
        duration: 0,
        created: "",
        updated: "",
    });
    const [endPrice, setEndPrice] = useState(0.00);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageStatus, setStatus] = useState('green');
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/e-shop/products/${productCode}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Produkte");
                }
                else{
                    const data = await response.json();
                    setProduct(data);
                    setError(false);
                    setLoading(false);

                    let price = data.price * data.duration;
                    let formattedPrice = price.toFixed(2).replace('.', ',');
                    setEndPrice(formattedPrice);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [productCode]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Produkt wurde leider nicht gefunden.</h1>
                    <p>Das Produkt mit der ID {productCode} wurde nicht gefunden oder steht derzeit leider nicht zur Verfügung.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <div>
                    <CheckIcon size={"75px"} fill={"none"} color={"yellowgreen"}></CheckIcon>
                    <p className="secondaryText">Artikel: {productCode}</p>
                    <h1>Vielen Dank für Ihre Bestellung!</h1>
                    <p>Vielen Dank für Ihren heutigen Einkauf bei OneSurvey. Hiermit bestätigen wir den erfolgreichen Eingang Ihrer Bestellung. Sie erhalten eine separate Bestellbestätigung per E-Mail.</p>
                    <div className="spacer4"></div>
                    <div className="divider"></div>
                    <div className="spacer4"></div>
                    <h3>Details zu Ihrer Bestellung:</h3>
                    <ul>
                        <li>Produkt: {product.title}</li>
                        <li>ArtikelNr: {product.code}</li>
                        <li>Gesamtpreis: {endPrice} €</li>
                        <li>Laufzeit: {product.duration} {product.duration > 1 ? "Monate":"Monat"}</li>
                    </ul>
                    <strong>
                        {product.autoRenew ? "Das Abonnement verlängert sich nach Ablauf des Zeitraums automatisch. Die Kündigungsfrist beträgt 1 Woche vor Ende der Laufzeit." : `Das Abonnement läuft automatisch nach der Laufzeit von ${product.duration} ${product.duration > 1 ? "Monate":"Monat"} aus. Bitte beachten Sie, dass Sie nach Ablauf des Abonnements keinen Zugang mehr zur OneSurvey Cloud besitzen.`}
                    </strong>
                </div>
                <div className="spacer"></div>
                <button onClick={()=>navigate('/myaccount/subscriptions')} className="btn-primary">Meine Abonnements verwalten</button>
                <button onClick={()=>navigate('/')} className="btn-secondary">Zurück zur Startseite</button>
            </div>
        </div>
    );
}

export default ProductPageOrder