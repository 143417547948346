import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import "../../../../App.css";
import "./ProductPage.css"
import Toast from "../../../../components/UIComponents/tools/Toast";

const ProductPageOrder = () => {
    const { productCode } = useParams();
    const [product, setProduct] = useState({
        id: 0,
        code: "",
        title: "",
        description: "",
        price: 0.00,
        status: false,
        autoRenew: false,
        type: "",
        duration: 0,
        created: "",
        updated: "",
    });
    const [endPrice, setEndPrice] = useState(0.00);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageStatus, setStatus] = useState('green');
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/e-shop/products/${productCode}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Produkte");
                }
                else{
                    const data = await response.json();
                    setProduct(data);
                    setError(false);
                    setLoading(false);

                    let price = data.price * data.duration;
                    let formattedPrice = price.toFixed(2).replace('.', ',');
                    setEndPrice(formattedPrice);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [productCode]);

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/${product.code}/add/`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {

                const text = await response.text();
                if (text === "FreeTrial was already used."){
                    setStatus('red');
                    setMessage("Der kostenlose Testmonat wurde bereits in Anspruch genommen. Eine erneute Aktivierung ist nicht möglich.");
                }
                else{
                    throw new Error("Error");
                }
            }
            else{
                const text = await response.text();
                console.log(text)
                setStatus('green');
                setMessage("Abonnement wurde erfolgreich hinzugefügt. Sie werden gleich weitergeleitet.");
                navigate(`/e-shop/${product.code}/checkout`);
            }
        } catch (error) {
            setStatus('red');
            setMessage("Fehler beim Hinzufügen des Abonnements. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
        }
    }

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Produkt wurde leider nicht gefunden.</h1>
                    <p>Das Produkt mit der ID {productCode} wurde nicht gefunden oder steht derzeit leider nicht zur Verfügung.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <div>
                    <p className="secondaryText">Artikel: {productCode}</p>
                    <h1>{product.title}</h1>
                    <strong>
                        <p style={{fontWeight: "normal", fontSize: "22px"}}>{product.price > 0 ? `${product.price.toFixed(2).replace('.', ',')} €` + `${product.type === "Monthly" ? " / Monat" : ""}` : "Kostenlos"}</p>
                    </strong>
                    <div className="spacer4"></div>
                    <div className="divider"></div>
                    <div className="spacer4"></div>
                    <h3>Bitte stimmen Sie unseren AGBs, Nutzungsbedingungen und Datenschutzbedingungen zu</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="order-aknowledge">
                            <div className="order-aknowledge-input-labelwrapper">
                                <label className="order-aknowledge-label" htmlFor="aknowledge">
                                    <p>
                                        Ich bestätige hiermit, dass ich das Produkt <strong>{product.title}</strong> (Laufzeit: {product.duration} {product.duration > 1 ? "Monate":"Monat"}, {product.autoRenew ? "mit automatischer Verlängerung" : "ohne Verlängerung"}) kostenpflichtig erwerben möchte. <br />
                                        Der Gesamtpreis beträgt <strong>{endPrice} €</strong>.<br />
                                        Mit dem Kauf stimme ich den <a href="https://onecoding.de/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> und den <a href="https://onecoding.de/agb" target="_blank" rel="noopener noreferrer">AGBs</a> der OneCoding Software Rottenbiller Schenk GbR zu.
                                    </p>
                                </label>
                            </div>
                            <div className="order-aknowledge-input-inputwrapper">
                                <input
                                    className="order-aknowledge-input"
                                    type="checkbox"
                                    name="aknowledge"
                                    required
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Jetzt kostenpflichtig kaufen</button>
                        <p className={`message ${messageStatus}`} id="message">{message}</p>
                        <div className="spacer2"></div>
                    </form>
                    <div className="spacer3"></div>
                </div>
            </div>
            <div className="status-bar">
                <div className="status-bar-items">
                    <span>
                        <h2>Ihre heutige Rechnung:</h2>
                    </span>
                    <span>
                        <p className="price-tag">{endPrice} €</p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ProductPageOrder