import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../../components/UIComponents/tools/Toast";
import "./EditSurvey.css";
import "../../../../../App.css";

const EditSurvey = () => {
    const { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null)
    const [surveyTitle, setSurveyTitle] = useState(null);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
        layoutCode: "",
    });
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [error, setError] = useState(false);

    useEffect(() => {
        const init = async () => {
            await authorizationCheck();
            await SubscriptionCheck();
            await fetchSurveyId();
        };
        init();
    }, [surveyCode]);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                showToast('Keine aktive OneSurvey-Subscription', 'error');
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                setError(true);
                showToast('Keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie diese Seite neu.', 'error');
            } else {
                console.log("Berechtigungsprüfung erfolgreich.");
            }
        } catch (error) {
            setError(true);
            console.error("Fehler bei der Berechtigungsprüfung:", error);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-ID");

            const data = await response.json();
            setSurveyId(data.id);
            fetchSurveyData(data.id);
        } catch (error) {
            console.error(error);
            showToast('Keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie diese Seite neu.', 'error');
        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
            setSurveyTitle(data.title);
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Abrufen der Umfrage. Bitte laden Sie diese Seite neu.', 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSurvey((prevSurvey) => ({
            ...prevSurvey,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSurvey((prevSurvey) => ({
            ...prevSurvey,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedSurvey = {
            ...survey,
            isSecured: false, //Not implemented yet.
        };

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/edit`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedSurvey),
            });

            if (response.ok) {
                showToast('Umfrage erfolgreich aktualisiert', 'success');
                setTimeout(()=>{navigate(`/mysurveys/${surveyCode}`)}, 1000);
            } else {
                showToast('Fehler beim Aktualisieren der Umfrage. Bitte laden Sie die Seite neu.', 'error');
                throw new Error("Fehler beim Aktualisieren der Umfrage");
            }
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Aktualisieren der Umfrage");
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Umfrage mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs"><a href='/mysurveys'>Meine Umfragen &gt;</a> <a href={`/mysurveys/${surveyCode}`}>{surveyTitle} &gt; </a> <a href={`/mysurveys/${surveyCode}/edit`}>Bearbeiten</a></span>
                <h1>Ihre Umfrage bearbeiten</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="checkbox-label">
                            <input
                                className="checkbox-input"
                                type="checkbox"
                                name="status"
                                checked={survey.status}
                                onChange={handleCheckboxChange}
                            />Veröffentlicht</label>
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Layout:</label>
                        <select
                            className="dropdown"
                            name="layoutCode"
                            value={survey.layoutCode}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled>
                                Bitte wählen Sie ein Layout aus.
                            </option>
                            <option value="SITE">
                                Alle Fragen auf einer Seite
                            </option>
                            <option value="PAGE">
                                Pro Seite jeweils eine Frage
                            </option>
                        </select>
                    </div>

                    <div className="spacer3"></div>
                    <div>
                        <label>Titel:</label>
                        <input
                            type="text"
                            value={survey.title}
                            name="title"
                            onChange={handleInputChange}
                            placeholder="Musterumfrage der Muster GmbH"
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Beschreibung:</label>
                        <textarea
                            className="textarea"
                            id="survey-description"
                            value={survey.description}
                            onChange={handleInputChange}
                            name="description"
                            placeholder="Bitte geben Sie eine kurze Beschreibung Ihrer Umfrage an."
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zur Startseite:</label>
                        <input
                            type="text"
                            value={survey.linkHome}
                            name="linkHome"
                            onChange={handleInputChange}
                            placeholder="https://ihre-webseite.de"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zum Impressum:</label>
                        <input
                            type="text"
                            value={survey.linkImpressum}
                            name="linkImpressum"
                            onChange={handleInputChange}
                            placeholder="https://ihre-webseite.de/impressum"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zur Datenschutzerklärung:</label>
                        <input
                            type="text"
                            value={survey.linkPrivacy}
                            name="linkPrivacy"
                            onChange={handleInputChange}
                            placeholder="https://ihre-webseite.de/datenschutz"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <button className="btn-primary" type="submit">Umfrage ändern</button>
                </form>
                <button onClick={()=>navigate(`/mysurveys/${surveyCode}`)} className="btn-secondary">Abbrechen</button>
                {message && <p>{message}</p>}
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default EditSurvey;