import React, { useState } from 'react';
import '../../../App.css';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import PublicHeader from "../../../components/PublicHeader";

const SendVerificationCode = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageStatus, setStatus] = useState('green');
    const [captchaToken, setCaptchaToken] = useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!captchaToken) {
            setMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
            setStatus('red');
            return;
        }

        try {
            const response = await fetch('https://api.onesurvey.de/auth/send-verify-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.status === 200) {
                setMessage("Verifizierungscode wurde gesendet");
            } else {
                setMessage("Account nicht gefunden oder E-Mail-Adresse bereits verifiziert.");
            }
        } catch (error) {
            setMessage("Fehler beim Senden des Verifizierungscodes");
        }
    };

    const onCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div>
            <PublicHeader></PublicHeader>
            <div className="app-container">
                <h2>E-Mail-Adresse verifizieren</h2>
                <p>Bitte geben Sie nachfolgend Ihre E-Mail-Adresse an, die Sie verifizieren möchten.</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        value={email}
                        placeholder="max.mustermann@domain.de"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    /><br/>
                    <HCaptcha
                        sitekey="c3122632-8252-4e70-a38c-3f2857769e9c"
                        onVerify={onCaptchaVerify}
                    />
                    <button type="submit">E-Mail mit Verifizierungscode senden</button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default SendVerificationCode;
