import React, { useEffect, useState } from "react";
import logo from './img.png';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image, Link,
} from "@react-pdf/renderer";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
        fontSize: "15px",
    },
    section: {
        margin: 10,
        padding: 10,
        borderBottom: "1px dotted #ccc",
    },
    footer: {
        position: "absolute",
        bottom: 5,
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: "100%",
        height: 550,
    },
    image: {
        width: 250,
        height: 100,
        marginBottom: 0,
    },
    logo: {
        position: 'absolute',
        top: -2,
        right: 10,
        width: 150,
        height: 40,
    },
    pageTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: 5,
    },
    title: {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: -15,
        marginBottom: 5,
    },
    fieldTitle: {
        fontWeight: "bold",
        fontSize: "12px",
        marginTop: -15,
        marginBottom: 5,
    },
    fieldData: {
        fontSize: "10px",
        marginBottom: 5,
    },
    footerText: {
        color: "grey",
        fontSize: "8px",
        marginBottom: 0,
    },
});

function BasicDocument() {
    const navigate = useNavigate();
    const [entry, setEntry] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [account, setAccount] = useState(null);
    const [surveyCode, setSurveyCode] = useState(null);
    const [error, setError] = useState(false);
    const { entryId } = useParams();
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchEntryData = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/graph/data/entry/${entryId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    setError(true);
                } else {
                    const data = await response.json();
                    setEntry(data);
                    setSurveyCode(data.surveyCode);
                }
            } catch (error) {
                console.error("Error fetching entry data", error);
                setError(true);
            }
        };

        const loadUserdata = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const accountData = await response.json();
                    setAccount(accountData);
                } else {
                    setError(true);
                }
            } catch (error) {
                console.error("Error fetching account data", error);
                setError(true);
            }
        };

        const SubscriptionCheck = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok){
                    throw new Error("No active OneSurvey Subscription.");
                }

            } catch (error) {
                navigate('/e-shop');
            }
        };

        SubscriptionCheck();
        loadUserdata();
        fetchEntryData();
    }, [entryId, surveyCode]);

    useEffect(() => {

        const fetchSurveyId = async () => {
            if (!surveyCode) return;

            try {
                const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-ID");

                const data = await response.json();
                setSurvey(data);
            } catch (error) {
                console.error("Error fetching survey data", error);
                setError(true);
            }
        };

        if (surveyCode) {
            fetchSurveyId();
        }
    }, [surveyCode]);

    if (error) {
        return <Text>Fehler beim Laden des Eintrags {entryId}.</Text>;
    }

    if (!entry) {
        return <Text>Lade Eintragsdaten...</Text>;
    }

    return (
        <div>
            <Header></Header>
            <div>
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            {/* Header Section */}
                            <View style={styles.section}>
                                <Text style={styles.pageTitle}>OneSurvey Datenexport</Text>
                                <Image style={styles.logo} src={logo}/>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.title}>Eintrags-ID: {entry.id}</Text>
                                <Text style={styles.fieldData}>Erstellungsdatum: {entry.convertedCreationTime} Uhr</Text>
                                <Text style={styles.fieldData}>Dauer: {entry.convertedDuration}</Text>
                                <Text style={styles.fieldData}>Umfrage-ID: {entry.surveyCode?.toUpperCase()}</Text>
                                <Text style={styles.fieldData}>Kampagne: {entry.campaignTitle || 'Keine Kampagne hinterlegt.'}</Text>
                                <Text style={styles.fieldData}>Link zur Umfrage: <Link style={styles.fieldData}>{`https://app.onesurvey.de/s/${entry.surveyCode}`}</Link></Text>
                            </View>

                            {/* Survey Data Section */}
                            {entry.data && entry.data.map((field) => (
                                <View key={field.id} style={styles.section}>
                                    <Text style={styles.fieldTitle}>{field.fieldTitle}</Text>
                                    <Text style={styles.fieldData}>{field.data || "Keine Daten vorhanden."}</Text>
                                </View>
                            ))}

                            {/* Footer Section */}
                            <View style={styles.footer}>
                                <Text style={styles.footerText}>
                                    OneSurvey by OneCoding - Benutzer:
                                    {account ? ` ${account.firstname} ${account.lastname}` : 'Daten werden geladen'} -
                                    Exportzeit: {new Date().toLocaleString()} Uhr
                                </Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                <div className="app-container">
                    <a className="btn btn-primary" href={`/data/entry/${entry.id}`}>Zurück zur Übersicht</a>
                </div>
            </div>

        </div>
    );
}

export default BasicDocument;