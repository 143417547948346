import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Register/Registration.css';
import AccountDeactivatedIcon from "../../../components/UIComponents/icons/AccountDeactivated";

const AccountBlocked = () => {
    const navigate = useNavigate();

    return (
        <div className="app-registration">
            <div className="registration">
                <div className="registration-container">
                    <div className="registration-wrapper">
                        <img
                            src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                            width="250px"
                            alt="OneSurvey Logo"
                        />
                        <p></p>
                        <AccountDeactivatedIcon size={"60px"} fill={"none"} color={"#3a3a3a"}></AccountDeactivatedIcon>
                        <h2>Ihr Account wurde deaktiviert.</h2>
                        <p>Wir haben Ihren Account deaktiviert, da Sie gegen die Nutzungsbedingungen von OneSurvey verstoßen haben. Für eine Reaktivierung, wenden Sie sich bitte an uns.</p>
                        <a href="mailto:info@onecoding.de">Kontaktieren Sie uns</a>&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                        <a href="/login">Zurück zur Anmeldung</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountBlocked;
