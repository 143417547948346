import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeader from "../../../components/survey/CustomHeader";
import TextField from "../../../components/inputForms/TextField";
import TextArea from "../../../components/inputForms/TextArea";
import RatingStars from "../../../components/inputForms/RatingStars";
import Checkbox from "../../../components/inputForms/Checkbox";
import Dropdown from "../../../components/inputForms/Dropdown";
import NPSParent from "../../../components/inputForms/NPSParent";
import PublicLoadingScreen from "../../../components/UIComponents/screens/PublicLoadingScreen";
import '../Survey.css';
import '../../../App.css';
import SurveyHeader from "../../../components/SurveyHeader";
import WarningIcon from "../../../components/UIComponents/icons/Warning";

export default function Survey() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [surveyInfo, setSurveyInfo] = useState(null);
    const [surveyFields, setSurveyFields] = useState(null);
    const [fieldValues, setFieldValues] = useState({});
    const [fieldOptions, setFieldOptions] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [isSurveyStarted, setSurveyStarted] = useState(false);
    const [progressBar, setProgressBar] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [campaign, setCampaign] = useState("");

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const surveyInfoResponse = await fetch(`https://api.onesurvey.de/opensurvey/v1/surveyinfo/${id}`);
                if (!surveyInfoResponse.ok) throw new Error('Network response was not ok for survey info');
                const surveyInfoResult = await surveyInfoResponse.json();
                setSurveyInfo(surveyInfoResult);

                const surveyFieldsResponse = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfoResult.id}/fields/`);
                if (!surveyFieldsResponse.ok) throw new Error('Network response was not ok for survey fields');
                const surveyFieldsResult = await surveyFieldsResponse.json();
                setSurveyFields(surveyFieldsResult);

                const options = await Promise.all(surveyFieldsResult.map(async (field) => {
                    const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/field/${field.id}/options`);
                    if (!response.ok) return { [field.id]: [] };
                    const result = await response.json();
                    return { [field.id]: result };
                }));
                const statistics = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfoResult.id}/statistics/visited`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                })
                const optionsMap = options.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                setFieldOptions(optionsMap);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        const checkCampaign = async () => {
            const campaignCode = params.get('oscampaign');
            if (campaignCode != null){
                setCampaign(campaignCode.toUpperCase());
                let isValid = false;
                try{
                    const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/validate-campaign/${campaignCode}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        setCampaign("");
                    }
                    else{
                        setCampaign(campaignCode);
                        isValid = true;
                    }

                }catch (error){
                    console.log(error);
                }

                if (isValid){
                    try{
                        const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/campaign/${campaignCode}/statistics/visited`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }catch (error){
                        console.log(error);
                    }
                }
            }
        };

        const localStartTime = getLocalISOTime();
        setStartTime(localStartTime);
        fetchSurveyData();
        checkCampaign();
    }, [id]);

    const getLocalISOTime = () => {
        const now = new Date();
        return now.toISOString();
    };

    const handleChange = (fieldId, value) => {
        setFieldValues(prevState => ({
            ...prevState,
            [fieldId]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            startTime: startTime,
            endTime: getLocalISOTime(),
            campaignTag: campaign,
            data: surveyFields.map(field => ({
                fieldId: field.id,
                value: String(fieldValues[field.id] || "")
            }))
        };
        try {
            const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfo.id}/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            if (!response.ok) throw new Error('Netzwerkantwort war nicht ok für das Hinzufügen der Umfrage');
            navigate(`/s/${id}/checkout`);
        } catch (error) {
            setError(error);
        }
    };

    const handleNPSChange = (fieldId, value) => {
        if (value === 0){
            value = "0";
        }
        setFieldValues(prevState => ({
            ...prevState,
            [fieldId]: value
        }));
    };

    const renderField = (field) => {

        document.title = surveyInfo.title;

        const fieldProps = {
            field: field,
            value: fieldValues[field.id] || '',
            onChange: (e) => handleChange(field.id, e.target.value)
        };

        const npsFieldProps = {
            field: field,
            value: fieldValues[field.id] || "0",
            onChange: (e) => handleNPSChange(field.id, e.target.value)
        };

        switch (field.type) {
            case 1:
                return <TextField field={field} {...fieldProps} />;
            case 2:
                return <TextArea field={field} {...fieldProps} />;
            case 3:
                return <RatingStars field={field} {...fieldProps} />;
            case 4:
                return Array.isArray(fieldOptions[field.id]) ?
                    (
                        <Checkbox field={field} fieldOptions={fieldOptions[field.id]} {...fieldProps} />
                    )
                    :
                    (
                        <p>Keine Optionen vorhanden. Feld derzeit nicht verfügbar.</p>
                    );
            case 5:
                return Array.isArray(fieldOptions[field.id]) ?
                    (
                        <Dropdown field={field} fieldOptions={fieldOptions[field.id]} {...fieldProps} />

                    )
                    :
                    (
                        <p>Keine Optionen verfügbar. Feld derzeit nicht verfügbar.</p>
                    );
            case 8:
                return <NPSParent field={field} {...npsFieldProps} />;
            default:
                return null;
        }
    };

    const startSurvey = () => {
        setProgressBar(((1) / surveyFields.length) * 100);
        setInterval(function(){setSurveyStarted(true)},300);
        const localStartTime = getLocalISOTime();
        setStartTime(localStartTime);
    }

    const renderPageLayout = () => {

        if (currentPage === 0 && isSurveyStarted == false) {
            return (
                <div>
                    <div style={{
                        margin: '0px',
                        backgroundColor: 'transparent',
                        borderRadius: '0px',
                        width: '100%',
                        height: '15px',
                        marginBottom: '0px'
                    }}>
                        <div style={{
                            height: '100%',
                            width: `${progressBar}%`,
                            backgroundColor: 'rgb(25,88,229)',
                            borderRadius: '0px',
                            transition: 'width 0.3s ease-in-out'
                        }}>

                        </div>
                    </div>
                    <div style={{padding: '20px'}}>
                        <h1>{surveyInfo.title}</h1>
                        <p>{surveyInfo.description}</p>
                        <div className="divider"></div>
                        <h3>Willkommen zur Umfrage!</h3>
                        <p>Drücken Sie auf "Jetzt mit Umfrage beginnen", um die erste Frage zu sehen.</p>
                        <button onClick={() => {startSurvey()}}>Jetzt mit Umfrage beginnen</button>
                        <div className="spacer2"></div>
                        <div className="spacer2"></div>
                        <div className="spacer2"></div>
                        <div style={{position: 'absolute', bottom: '0px', paddingRight: '20px'}}>
                            <p id="datasecurity" className="secondaryText">Dieser Inhalt wird vom Besitzer des Formulars erstellt. Die von Ihnen übermittelten Daten werden an den Formularbesitzer gesendet. Die OneCoding Software Rottenbiller Schenk GbR (nachfolgend OneSurvey) ist nicht für die Datenschutz- oder Sicherheitspraktiken seiner Kunden, einschließlich derer dieses Formularbesitzers, verantwortlich. Geben Sie niemals Ihr Kennwort oder andere vertrauliche Informationen heraus.</p>
                            <a href={surveyInfo.linkHome}>Informationen zum Formularbesitzer</a><br/>
                            <a href={surveyInfo.linkImpressum}>Impressum</a>&nbsp;&nbsp;&nbsp;
                            <a href={surveyInfo.linkPrivacy}>Datenschutz</a><br/>
                            <div className="spacer"></div>
                        </div>
                    </div>
                </div>
            );
        }

        const progressPercentage = ((currentPage + 1) / surveyFields.length) * 100;
        const field = surveyFields[currentPage];

        return (
            <div style={{padding: '0'}}>
                <div>
                    <div style={{
                        margin: '0px',
                        backgroundColor: 'rgba(218,218,218,0.32)',
                        borderRadius: '0px',
                        width: '100%',
                        height: '15px',
                        marginBottom: '0px'
                    }}>
                        <div style={{
                            height: '100%',
                            width: `${progressPercentage}%`,
                            backgroundColor: 'rgb(25,88,229)',
                            borderRadius: '0px',
                            transition: 'width 0.3s ease-in-out'
                        }}></div>
                    </div>
                </div>
                <div style={{padding: '20px'}}>
                    <p style={{color: "grey"}}>{currentPage + 1} / {surveyFields.length} Fragen</p>
                    <div style={{minHeight: "200px"}}>
                        <h3>{field.title}</h3>
                        <p>{field.description}</p>
                        {renderField(field)}
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        {currentPage > 0 && <button onClick={() => setCurrentPage(currentPage - 1)}>Zurück</button>}
                        {currentPage < surveyFields.length - 1
                            ? <button onClick={() => setCurrentPage(currentPage + 1)}>Weiter</button>
                            : <button type="submit" onClick={handleSubmit}>Absenden</button>}
                    </div>
                    <div className="spacer"></div>
                </div>
            </div>
        );
    };

    if (loading) return <PublicLoadingScreen />;
    if (error){
        return (
            <div>
                <SurveyHeader></SurveyHeader>
                <div className="app-container">
                    <WarningIcon color={"black"} fill={"none"} size={"80px"}></WarningIcon>
                    <h1>Diese Umfrage wurde leider nicht gefunden.</h1>
                    <p>Die gewünschte Umfrage wurde nicht gefunden oder steht derzeit leider nicht zur Verfügung.</p>
                    <button onClick={()=> navigate('/s')}>zurück zur Startseite</button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <CustomHeader logoUrl={surveyInfo.frontendLogo} headerBackground={surveyInfo.headerBackground} />
            <div className="survey-container" style={{
                backgroundImage: `url(${surveyInfo.siteBackground})`,
                backgroundSize: 'cover'
            }}>
                <div className="survey" style={{padding: '0'}}>
                    {surveyInfo.layoutType === "PAGE"
                        ? renderPageLayout()
                        :
                        <div style={{padding: '20px'}}>
                            <h1>{surveyInfo.title}</h1>
                            <p>{surveyInfo.description}</p>
                            <form onSubmit={handleSubmit}>
                                {surveyFields.map(field => (
                                    <div key={field.id}>
                                        <h3>{field.title}</h3>
                                        <p>{field.description}</p>
                                        {renderField(field)}
                                    </div>
                                ))}
                                <button type="submit">Umfrage absenden</button>
                            </form>
                            <div>
                                <p id="datasecurity" className="secondaryText">Dieser Inhalt wird vom Besitzer des Formulars erstellt. Die von Ihnen übermittelten Daten werden an den Formularbesitzer gesendet. Die OneCoding Software Rottenbiller Schenk GbR (nachfolgend OneSurvey) ist nicht für die Datenschutz- oder Sicherheitspraktiken seiner Kunden, einschließlich derer dieses Formularbesitzers, verantwortlich. Geben Sie niemals Ihr Kennwort oder andere vertrauliche Informationen heraus.</p>
                                <a href={surveyInfo.linkHome}>Informationen zum Formularbesitzer</a><br/>
                                <a href={surveyInfo.linkImpressum}>Impressum</a>&nbsp;&nbsp;&nbsp;
                                <a href={surveyInfo.linkPrivacy}>Datenschutz</a><br/>
                                <div className="spacer"></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
