import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../../../../components/Header';
import LoadingScreen from '../../../../../components/UIComponents/screens/LoadingScreen';
import Toast from '../../../../../components/UIComponents/tools/Toast';
import '../../../../../App.css';
import EmailInput from "../../../../../components/UIComponents/tools/EmailInput";

const EditCampaign = () => {
    const { campaignCode } = useParams();
    const accessToken = sessionStorage.getItem("OSACCESS");
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState({});
    const [campaignTitle, setTitle] = useState({});
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    // Kampagnendetails abrufen, um das Titelfeld vorab zu befüllen
    const fetchCampaignDetails = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Kampagnendaten.');
            }

            const data = await response.json();
            setCampaign(data);
            setTitle(data.title);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            showToast('Fehler beim Laden der Kampagne.', 'error');
        }
    };

    useEffect(() => {
        fetchCampaignDetails();
    }, []);

    const handleTitleChange = (e) => {
        setCampaign({ ...campaign, title: e.target.value });
    };

    // Kampagnentitel aktualisieren
    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ title: campaign.title })
            });

            if (!response.ok) {
                throw new Error('Fehler beim Speichern der Kampagnendaten.');
            }

            showToast('Kampagne erfolgreich aktualisiert.', 'success');
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Speichern der Kampagne.', 'error');
        }
    };

    // Kampagne löschen
    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns/${campaignCode}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Löschen der Kampagne.');
            }

            showToast('Kampagne erfolgreich gelöscht.', 'success');
            navigate('/campaigns');
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Löschen der Kampagne.', 'error');
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Startseite &gt;</a>
                    <a href={`/campaigns/`}> OneSurvey Kampagnen &gt;</a>
                    <a href={`/campaigns/${campaign.code}`}> {campaignTitle} &gt;</a>
                    <a href={`/campaigns/${campaign.code}/edit`}> Bearbeiten</a>
                </span>
                <h1>Kampagne bearbeiten</h1>
                <div className="survey-section action-card">
                    <h2>Kampagnentitel ändern</h2>
                    <p>Ändern Sie den Titel Ihrer Kampagne. Der Titel wird beim Versand der Kampagne als Absendername verwendet.</p>
                    <form onSubmit={handleSave}>
                        <div className="form-group">
                            <label htmlFor="title">Kampagnentitel:</label>
                            <input
                                type="text"
                                id="title"
                                className="form-control"
                                value={campaign.title}
                                onChange={handleTitleChange}
                            />
                        </div>
                        <div className="spacer4"></div>
                        <button type="submit" className="btn btn-primary">
                            Änderungen speichern
                        </button>
                    </form>
                </div>
                <div className="survey-section action-card">
                    <h2>Kampagne löschen</h2>
                    <p>Löschen Sie diese Kampagne. Die mit dieser Kampagne erhobenen Daten bleiben erhalten und werden nicht gelöscht.</p>
                    <form onSubmit={handleDelete}>
                        <div className="aknowledge">
                            <div className="aknowledge-input-labelwrapper">
                                <label className="order-aknowledge-label" htmlFor="aknowledge">
                                    <p>Ich möchte diese Kampagne unwiderruflich löschen.</p>
                                </label>
                            </div>
                            <div className="aknowledge-input-inputwrapper">
                                <input
                                    className="aknowledge-input"
                                    type="checkbox"
                                    name="aknowledge"
                                    required
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn-danger">
                            Kampagne löschen
                        </button>
                    </form>
                </div>
                <button type="button" className="btn btn-primary" onClick={() => navigate(`/campaigns/${campaign.code}`)}>
                    Zurück zur Kampagnenübersicht
                </button>

                {toast.show && (
                    <Toast message={toast.message} type={toast.type} onClose={hideToast} />
                )}
            </div>
        </div>
    );
};

export default EditCampaign;