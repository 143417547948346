import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../../components/Header";
import '../../../../../App.css';
import './Design.css';
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import subscriptionDetails from "../../../Account/Subscriptions/SubscriptionDetails";

const DesignSurvey = () => {
    let { surveyCode} = useParams();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [backgroundFile, setBackgroundFile] = useState(null);
    const [logoPreview, setLogoPreview] = useState(`https://appcdn.onesurvey.de/appcontent/headerlogos/S${surveyCode.toUpperCase()}.png`);
    const [backgroundPreview, setBackgroundPreview] = useState(`https://appcdn.onesurvey.de/appcontent/sitebackground/S${surveyCode.toUpperCase()}.png`);
    const [headerColor, setHeaderColor] = useState("#ffffff");

    //Message Fields
    const [message, setMessage] = useState("");
    const [statusLogo, setStatusLogo] = useState("");
    const [statusBackground, setStatusBackground] = useState("");
    const [statusColor, setStatusColor] = useState("");
    const [surveyId, setSurveyId] = useState(null)
    const [surveyTitle, setSurveyTitle] = useState(null);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });

    useEffect(() => {
        AuthorizationCheck();
        SubscriptionCheck();
        fetchSurveyId();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const AuthorizationCheck = async () => {

        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            })

            console.log(response);

            if (response.ok){
                console.log("Berechtigungsprüfung erfolgreich.");
            }
            else{
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        }
        catch (error){
            setError(true);
            console.log(error)
        }

    }

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-ID");

            const data = await response.json();
            setSurveyId(data.id);
            fetchSurveyData(data.id);
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
            setSurveyTitle(data.title);
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Abrufen der Umfrage-Daten");
        }
    };


    const handleFileChangeLogo = (e, setFile) => {

        const fileInput = document.getElementById('file-input-logo');
        const fileName = document.getElementById('file-name-logo');

        if (fileInput.files.length > 0) {
            fileName.textContent = fileInput.files[0].name;
        } else {
            fileName.textContent = 'Keine Datei ausgewählt';
        }

        setFile(e.target.files[0]);
        const previewURLLogo = URL.createObjectURL(e.target.files[0]);
        setLogoPreview(previewURLLogo)
        setStatusLogo("");
    };

    const handleFileChangeBackground = (e, setFile) => {

        const fileInput = document.getElementById('file-input-background');
        const fileName = document.getElementById('file-name-background');

        if (fileInput.files.length > 0) {
            fileName.textContent = fileInput.files[0].name;
        } else {
            fileName.textContent = 'Keine Datei ausgewählt';
        }

        setFile(e.target.files[0]);
        const previewURLBackground = URL.createObjectURL(e.target.files[0]);
        setBackgroundPreview(previewURLBackground);
        setStatusBackground("");
    };

    const uploadFile = async (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("surveyguid", surveyCode);

        const endpoint = type === "Logo"
            ? 'https://api.onesurvey.de/v1/imaging/upload/logo'
            : 'https://api.onesurvey.de/v1/imaging/upload/background';

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            });

            if (!response.ok) {
                const responseText = await response.text();
                let errorText = "";
                switch (responseText){
                    case "No file uploaded":
                        errorText = "Bitte laden Sie eine Datei hoch.";
                        break;
                    case "File type not allowed":
                        errorText = "Dateityp nicht erlaubt. Bitte laden Sie nur .png, .jpg und .jpeg Dateien hoch.";
                        break;
                    case "File size exceeds limit":
                        errorText = "Datei zu groß. Bitte laden Sie nur Dateien mit einer Größe bis zu 10MB hoch.";
                        break;
                }
                (type === "Logo") ? setStatusLogo(errorText) : setStatusBackground(errorText);
            }
            else{
                (type === "Logo") ? setStatusLogo("Logo wurde erfolgreich hochgeladen.") : setStatusBackground("Hintergrundbild wurde erfolgreich hochgeladen.");
            }
        } catch (error) {
            setMessage(`Fehler: ${error.message}`);
        }
    };

    const handleSubmitLogo = async (event) => {
        event.preventDefault();
        await uploadFile(logoFile, "Logo");
        fetchSurveyId(); //Fetch Data again for display preview
    };

    const handleSubmitBackground = async (event) => {
        event.preventDefault();
        await uploadFile(backgroundFile, "Background");
        fetchSurveyId(); //Fetch Data again for display preview
    };

    const handleHeaderColorChange = async (event) => {
        setHeaderColor(event.target.value);
    };

    const handleSaveHeaderColor = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyCode}/edit-background`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ colorCode: headerColor }),
            });

            if (!response.ok) {
                throw new Error('Failed to save header color');
            } else {
                setStatusColor('Hintergrundfarbe erfolgreich gespeichert.');
            }
        } catch (error) {
            setStatusColor('Fehler beim Speichern der Hintergrundfarbe.');
            setMessage(`Fehler: ${error.message}`);
        }
    };

    const handleDeleteLogo = async () => {

        const formData = new FormData();
        formData.append("surveyguid", surveyCode);

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/imaging/delete/logo`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to delete logo');
            } else {
                setStatusLogo('Logo erfolgreich gelöscht.');
                survey.frontendLogo = null;
                setSurvey(survey);
            }
        } catch (error) {
            setStatusLogo('Fehler beim Löschen des Logos.');
            setMessage(`Fehler: ${error.message}`);
        }
    };

    const handleDeleteBackground = async () => {

        const formData = new FormData();
        formData.append("surveyguid", surveyCode);

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/imaging/delete/background`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to delete background');
            } else {
                setStatusBackground('Hintergrundbild erfolgreich gelöscht.');
                survey.siteBackground = null;
                setSurvey(survey);
            }
        } catch (error) {
            setStatusBackground('Fehler beim Löschen des Hintergrundbildes.');
            setMessage(`Fehler: ${error.message}`);
        }
    };

    function toggleAccordion(sectionClass) {
        const accordion = document.querySelector(`.${sectionClass} .accordion`);
        accordion.classList.toggle('open');
    }

    if (error){
        return(
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        )
    }

    return(
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs"><a href='/mysurveys'>Meine Umfragen &gt; </a><a href={`/mysurveys/${surveyCode}`}>{surveyTitle} &gt; </a><a href={`/mysurveys/${surveyCode}/design-settings`}>Design-Einstellungen</a></span>
                <div className="editorial">
                    <h1>Design-Einstellungen Ihrer Umfrage</h1>
                    <p>Verwalten Sie die Designeinstellungen Ihrer Umfrage.</p>
                    <div className="spacer"></div>
                    <p>{message}</p>
                </div>
                <div className="site-section-logo">
                    <div className="accordion">
                        <div className="accordion-header" onClick={()=>toggleAccordion("site-section-logo")}>
                            <span className="accordion-toggle">
                                <img width="40px" src="/arrow-down.svg"></img>
                            </span>
                            <h2>Logo bearbeiten</h2>
                        </div>
                        <div className="accordion-content">
                            <div className="col-settings">
                                <p>Bearbeiten Sie das Logo Ihrer Umfrage. Laden Sie Ihr Logo Ihres Unternehmens hoch. Das Logo wird in der Kopfzeile der Umfrage angezeigt.</p>
                                <form onSubmit={handleSubmitLogo}>
                                    <div>
                                        <div className="file-upload">
                                            <input type="file" id="file-input-logo" className="file-input" onChange={(e) => handleFileChangeLogo(e, setLogoFile)} />
                                            <label htmlFor="file-input-logo" className="file-label">
                                                <span className="file-button">Logo auswählen</span>
                                                <span className="file-name" id="file-name-logo">Keine Datei ausgewählt</span>
                                            </label>
                                        </div>
                                    </div>
                                    <button className="btn-primary" type="submit">Logo hochladen</button>
                                    <p>{statusLogo}</p>
                                </form>
                                <div className="spacer"></div>
                            </div>
                            {
                                survey.frontendLogo ?
                                    <div className="col-preview">

                                        <p>Vorschau:</p>
                                        <img alt="Logo preview" src={logoPreview} width="400px"></img>
                                        <button className="btn-danger" onClick={()=>{handleDeleteLogo()}} type="submit">Logo löschen</button>
                                    </div>
                                    :
                                    <div className="col-preview"></div>
                            }
                        </div>
                    </div>
                </div>
                <div className="site-section-background">
                    <div className="accordion">
                        <div className="accordion-header" onClick={()=>toggleAccordion("site-section-background")}>
                            <span className="accordion-toggle">
                                <img width="40px" src="/arrow-down.svg"></img>
                            </span>
                            <h2>Hintergrundbild bearbeiten</h2>
                        </div>
                        <div className="accordion-content">
                            <div className="col-settings">
                                <p>Bearbeiten Sie das Hintergrundbild Ihrer Umfrage. Das Bild wird im Hintergrund der Umfrage angezeigt.</p>
                                <form onSubmit={handleSubmitBackground}>
                                    <div>
                                        <div className="file-upload">
                                            <input type="file" id="file-input-background" className="file-input" onChange={(e) => handleFileChangeBackground(e, setBackgroundFile)} />
                                            <label htmlFor="file-input-background" className="file-label">
                                                <span className="file-button">Hintergrundbild auswählen</span>
                                                <span className="file-name" id="file-name-background">Keine Datei ausgewählt</span>
                                            </label>
                                        </div>
                                    </div>
                                    <button className="btn-primary" type="submit">Hintergrundbild hochladen</button>
                                    <p>{statusBackground}</p>
                                </form>
                                <div className="spacer"></div>
                            </div>
                            {
                                survey.siteBackground ?
                                    <div className="col-preview">
                                        <p>Vorschau:</p>
                                        <img alt="Background preview" src={backgroundPreview} width="400px"></img>
                                        <button className="btn-danger" onClick={()=>{handleDeleteBackground()}} type="submit">Hintergrundbild löschen</button>
                                    </div>
                                    :
                                    <div className="col-preview"></div>
                            }
                        </div>
                    </div>
                </div>
                <div className="site-section-headercolor">
                    <div className="accordion">
                        <div className="accordion-header" onClick={() => toggleAccordion("site-section-headercolor")}>
                            <span className="accordion-toggle">
                                <img width="40px" src="/arrow-down.svg" alt="Toggle"></img>
                            </span>
                            <h2>Hintergrundfarbe Header anpassen</h2>
                        </div>
                        <div className="accordion-content">
                            <div className="col-settings">
                                <p>Wählen Sie eine passende Hintergrundfarbe für den Header Ihrer Umfrage. Diese Farbe wird im Kopfbereich Ihrer Umfrage angezeigt und trägt zur visuellen Konsistenz Ihrer Corporate Identity bei. Stellen Sie sicher, dass die gewählte Farbe gut mit dem Logo und dem restlichen Design Ihrer Umfrage harmoniert.</p>
                                <form onSubmit={handleSaveHeaderColor}>
                                    <div>
                                        <input
                                            id="colorpicker"
                                            type="color"
                                            value={headerColor}
                                            onChange={handleHeaderColorChange}
                                        />
                                    </div>
                                    <button className="btn-primary" type="submit">Farbe speichern</button>
                                    <p>{statusColor}</p>
                                </form>
                                <div className="spacer"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>
                <button className="btn-primary" onClick={()=>navigate(`/mysurveys/${surveyCode}`)}>Zurück zur Übersicht</button>
            </div>
        </div>
    )

}

export default DesignSurvey;