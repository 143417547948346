import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../components/UIComponents/tools/Toast";

const EditAdressInformation = () => {
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState({
        street: "",
        city: "",
        plz: "",
        country: "",
        company: false,
        companyname: ""
    });
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        loadUserProfile();
    }, []); // Lädt das Benutzerprofil nur einmal nach dem ersten Rendern

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    id: account.id,
                    street: account.street || "",
                    city: account.city || "",
                    plz: account.plz || "",
                    country: account.country || "",
                    company: account.company || false,
                    companyname: account.companyName || ""
                });
            } else {
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            showToast('Fehler beim Abrufen der Profilinformationen', 'error');
            console.error("Failed to load user profile", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                street: userInfo.street,
                city: userInfo.city,
                plz: userInfo.plz,
                country: userInfo.country,
                company: (userInfo.company == "true" ? true : (userInfo.company == true ? true : false)),
                companyname: (userInfo.company == "true" ? userInfo.companyname : (userInfo.company == true ? userInfo.companyname : "")),
            };

            const response = await fetch(`https://api.onesurvey.de/v1/account/edit/adress`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                showToast('Fehler beim Ändern der Daten', 'error');
                throw new Error('Fehler beim Ändern der Daten');
            }

            showToast('Adressinformationen erfolgreich abgeändert', 'success');
            setTimeout(()=>{navigate('/myaccount')}, 1000);
        } catch (error) {
            setError(error);
            console.error("Error updating data", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setUserInfo({ ...userInfo, [name]: newValue });
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Adressinformationen ändern</h1>
                    <p>Error: Daten konnten leider nicht geladen werden. Bitte versuchen Sie es in ein paar Minuten noch einmal.</p>
                </div>
            </div>
        );
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div className="account-edit-page">
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/adress-information`}> Adressinformationen ändern</a>
                </span>
                <h1>Adressinformationen ändern</h1>
                <p>Auf dieser Seite können Sie Ihre Adressinformationen anpassen.</p>
                <form onSubmit={handleSubmit} className="form-edit">
                    <label className="form-label">
                        <div className="spacer4"></div>
                        <div className="radio-group">
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value={false}
                                    name="company"
                                    checked={userInfo.company === false || userInfo.company === "false"}
                                    onChange={handleChange}
                                    className="form-radio"
                                />
                                Privatperson
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value={true}
                                    name="company"
                                    checked={userInfo.company === true || userInfo.company === "true"}
                                    onChange={handleChange}
                                    className="form-radio"
                                />
                                Unternehmen
                            </label>
                        </div>
                    </label>
                    {(userInfo.company === true || userInfo.company === "true") && (
                        <>
                            <div className="spacer3"></div>
                            <div className="form-group">
                                <label className="form-label">
                                    Unternehmensname:
                                    <div className="spacer4"></div>
                                    <input
                                        type="text"
                                        value={userInfo.companyname}
                                        name="companyname"
                                        placeholder="Muster GmbH"
                                        onChange={handleChange}
                                        style={{width: "100%"}}
                                        required
                                    />
                                </label>
                            </div>
                        </>
                    )}
                    <div className="spacer3"></div>

                    <div className="form-group">
                        <label>Straße und Hausnummer</label>
                        <input
                            type="text"
                            value={userInfo.street}
                            name="street"
                            placeholder="Musterstraße 4"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>PLZ</label>
                        <input
                            type="text"
                            value={userInfo.plz}
                            name="plz"
                            placeholder="12345"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Ort</label>
                        <input
                            type="text"
                            value={userInfo.city}
                            name="city"
                            placeholder="Musterstadt"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Land</label>
                        <input
                            type="text"
                            value={userInfo.country}
                            name="country"
                            placeholder="Deutschland"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <button type="submit" className="btn-primary">Daten ändern</button>
                        <button type="button" className="btn-secondary" onClick={() => navigate('/myaccount')}>Abbrechen</button>
                    </div>
                </form>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default EditAdressInformation;
