import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import './AddField.css';
import '../../../../../../App.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../../../components/UIComponents/tools/Toast";

const AddField = () => {
    const { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [fieldTypes, setFieldTypes] = useState([]);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [error, setError] = useState(false);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });

    useEffect(() => {
        authorizationCheck();
        fetchSurveyId();
        SubscriptionCheck();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                showToast('Keine gültige OneSurvey-Subscription.', 'error');
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                showToast('Sie haben keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie die Seite neu.', 'error');
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
            await fetchFieldTypes();
            await fetchSurveyData(data.id)
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            showToast('Sie haben keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const fetchFieldTypes = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/fieldtypes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldTypes(data);
        } catch (error) {
            console.log("Fehler beim Abrufen der Feldtypen:", error);
            showToast('Fehler beim Abrufen der Feldtypen. Bitte laden Sie die Seite neu.', 'error');

        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
        } catch (error) {
            console.error(error);
            showToast('Fehler beim Abrufen der Umfrage-Daten. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const handleAddField = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const fieldData = {
                position: 0,
                type: formData.get('type'),
                title: formData.get('title'),
                description: formData.get('description'),
                hintText: formData.get('hintText')
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/fields/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fieldData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(fieldData));
                console.log(response);
                showToast(`Frage ${fieldData.title} konnte leider nicht hinzugefügt werden. Bitte laden Sie die Seite neu.`, 'error');
                throw new Error('Fehler beim Hinzufügen der Daten');
            }
            else{
                const fieldId = await response.text();
                console.log(fieldId);
                showToast(`Frage ${fieldData.title} erfolgreich zur Umfrage hinzugefügt.`, 'success');

                if (fieldTypes.find((type) => type.id.toString() === fieldData.type).options){
                    setTimeout(()=>{navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/add-options`)}, 1000)
                }
                else{
                    setTimeout(()=>{navigate(`/mysurveys/${surveyCode}/fields/add/checkout`)}, 1000)
                }
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="add-fields-container">
                <h1>Neues Feld zu Ihrer Umfrage hinzufügen</h1>
                <p>Fügen Sie zu Ihrer Umfrage "{survey.title}" neue Felder hinzu. Bitte wählen Sie zu erst einen passenden Feldtyp aus.</p>
                <form onSubmit={handleAddField}>
                    <select
                        className="dropdown"
                        name="type"
                        required
                    >
                        <option value="" disabled>
                            Bitte wählen Sie einen Feldtyp aus
                        </option>
                        {fieldTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.description}
                            </option>
                        ))}
                    </select>
                    <div className="spacer"></div>
                    <div>
                        <label>Titel:</label>
                        <input
                            type="text"
                            placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                            name="title"
                            required
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Beschreibung:</label>
                        <textarea
                            className="textarea"
                            placeholder="Bitte bewerten Sie unsere Produkte & Dienstleistungen"
                            name="description"
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Hinweistext:</label>
                        <input
                            type="text"
                            placeholder="Dieser Text erscheint bei Textfeldern als Hinweis (vor der Eingabe)"
                            name="hintText"
                        />
                    </div>
                    <button className="btn-primary" type="submit">Feld hinzufügen</button>
                </form>
                <button className="btn-secondary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields`)}>Abbrechen</button>
                <p className="message">{message}</p>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );

}

export default AddField