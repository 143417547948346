import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import '../../../App.css';
import './AccountSetup.css';
import Toast from "../../../components/UIComponents/tools/Toast";

const AccountSetup = () => {
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        plz: "",
        country: "",
        username: "",
        company: false,
        companyname: ""
    });

    useEffect(() => {
        loadUserProfile();
    }, []);

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    id: account.id,
                    firstname: account.firstname,
                    lastname: account.lastname,
                    email: account.email,
                    phone: account.phone,
                    street: account.street,
                    city: account.city,
                    plz: account.plz,
                    country: account.country,
                    company: account.company,
                    companyname: account.companyName
                });
            } else {
                showToast('Keine Berechtigung. Bitte laden Sie die Seite neu', 'error');
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            console.error("Failed to load user profile", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: userInfo.phone,
                street: userInfo.street,
                city: userInfo.city,
                plz: userInfo.plz,
                country: userInfo.country,
                company: (userInfo.company == "true" ? true : (userInfo.company == true ? true : false)),
                companyname: (userInfo.company == "true" ? userInfo.companyname : (userInfo.company == true ? userInfo.companyname : ""))
            };

            console.log(updateData);

            const response = await fetch(`https://api.onesurvey.de/v1/account/setup`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                const text = await response.text();
                if (text === "E-Mail already used"){
                    showToast(`Die E-Mail-Adresse ${userInfo.email} wird bereits von einem anderen Konto verwendet.`, 'error');
                }
                else{
                    showToast('Fehler bei der Accounteinrichtung. Bitte laden Sie die Seite neu.', 'error');
                }
                throw new Error('Fehler beim Ändern der Daten');
            }

            console.log("Data updated successfully");
            showToast('Account erfolgreich eingerichtet', 'success');
            setTimeout(()=>{navigate('/myaccount')}, 1000);
        } catch (error) {
            setError(error);
            console.log(`Error ${error}`);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setUserInfo({ ...userInfo, [name]: newValue });
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div className="account-setup-page">
            <Header />
            <div className="app-container">
                <h1>Ihr Profil einrichten</h1>
                <p>Bitte richten Sie Ihren OneSurvey Account ein und fügen folgende Informationen hinzu.</p>
                <div className="spacer3"></div>
                <form onSubmit={handleSubmit} className="account-setup-form">
                    <div className="spacer3"></div>
                    <h2>Persönliche Informationen</h2>
                    <label className="form-label">
                        Vorname:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.firstname}
                            name="firstname"
                            placeholder="Max"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        Nachname:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.lastname}
                            name="lastname"
                            placeholder="Mustermann"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        E-Mail:
                        <div className="spacer4"></div>
                        <input
                            type="email"
                            value={userInfo.email}
                            name="email"
                            placeholder="example@example.com"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        Telefonnummer:
                        <div className="spacer4"></div>
                        <input
                            type="phone"
                            value={userInfo.phone}
                            name="phone"
                            placeholder="+49 123456789"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <h2>Adressdaten</h2>
                    <label className="form-label">
                        <div className="spacer4"></div>
                        <div className="radio-group">
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value={false}
                                    name="company"
                                    checked={userInfo.company === false || userInfo.company === "false"}
                                    onChange={handleChange}
                                    className="form-radio"
                                />
                                Privatperson
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value={true}
                                    name="company"
                                    checked={userInfo.company === true || userInfo.company === "true"}
                                    onChange={handleChange}
                                    className="form-radio"
                                />
                                Unternehmen
                            </label>
                        </div>
                    </label>
                    {(userInfo.company === "true" || userInfo.company === true) && (
                        <>
                            <div className="spacer3"></div>
                            <label className="form-label">
                                Unternehmensname:
                                <div className="spacer4"></div>
                                <input
                                    type="text"
                                    value={userInfo.companyname}
                                    name="companyname"
                                    placeholder="Muster GmbH"
                                    onChange={handleChange}
                                    className="form-input"
                                    required={userInfo.company}
                                />
                            </label>
                        </>
                    )}
                    <div className="spacer3"></div>
                    <label className="form-label">
                        Straße und Hausnummer:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.street}
                            name="street"
                            placeholder="Musterstraße 1"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        PLZ:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.plz}
                            name="plz"
                            placeholder="73463"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        Ort:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.city}
                            name="city"
                            placeholder="Musterstadt"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <label className="form-label">
                        Land:
                        <div className="spacer4"></div>
                        <input
                            type="text"
                            value={userInfo.country}
                            name="country"
                            placeholder="Deutschland"
                            onChange={handleChange}
                            className="form-input"
                            required
                        />
                    </label>
                    <div className="spacer3"></div>
                    <button type="submit" className="btn-primary">Account einrichten</button>
                    {message && <p className="form-message">{message}</p>}
                </form>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default AccountSetup;
