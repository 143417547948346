import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import '../../App.css';
import {useNavigate} from "react-router-dom";
import Home from "../Home/Home";

const AdminSettings = () => {

    const accessToken = sessionStorage.getItem('OSACCESS');
    const [access, setAccessToken] = useState();

    useEffect(() => {
        setAccessToken(accessToken);
    }, []);

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <h1>Admin-Settings</h1>
                <p>WebApp Adminbereich</p>
                <div style={{width: "50px"}}>
                    <p>AccessToken: {accessToken}</p>
                </div>
            </div>
        </div>
    );

}

export default AdminSettings;