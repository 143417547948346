import React from "react";
import './Dropdown.css';

export default function Dropdown ({ field, value, onChange, fieldOptions }) {

    return (
        <select className="select" value={value} onChange={onChange}>
            <option value="">{field.title}</option>
            {fieldOptions.map(option => (
                <option key={option.value} value={option.value}>
                    {option.title}
                </option>
            ))}
        </select>
    );
}