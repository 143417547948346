import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isValid, setIsValid] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [cookies, setCookie] = useCookies(['accessToken']);

    useEffect(() => {
        const cookieToken = cookies.accessToken;
        const sessionToken = sessionStorage.getItem('OSACCESS');

        if (cookieToken) {
            setAccessToken(cookieToken);
        } else if (sessionToken) {
            setAccessToken(sessionToken);
        } else {
            setIsValid(false);
        }
    }, [cookies]);

    useEffect(() => {

        const handleStorageChange = (event) => {
            if (event.key === 'logout') {
                sessionStorage.removeItem('OSACCESS');
                setIsValid(false);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        const validateToken = async () => {
            if (!accessToken) {
                navigate('/login', { state: { from: location } });
                return;
            }

            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/validate', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    // Token im sessionStorage und Cookie speichern
                    sessionStorage.setItem('OSACCESS', accessToken);
                    let expiryTime = new Date();
                    expiryTime.setTime(expiryTime.getTime() + 60 * 1000);
                    setCookie('accessToken', accessToken, { expires: expiryTime });
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            } catch (error) {
                setIsValid(false);
            }
        };

        const checkMaintenanceMode = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const status = await response.json();
                    if (status.value === "true") {
                        navigate('/sites/maintenance');
                    }
                } else {
                    console.log("Error fetching Portal APIs");
                }
            } catch (error) {
                console.log("Error fetching Portal APIs");
            }
        };

        if (accessToken) {
            validateToken();
            checkMaintenanceMode();
        }
    }, [accessToken, navigate, location]);

    if (isValid === null) {
        return <p></p>;
    }

    return isValid ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
