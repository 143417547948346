import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import Header from "../../../components/Header";
import LoadingScreen from "../../../components/UIComponents/screens/LoadingScreen";
import './Campaigns.css';
import '../../../App.css';
import loadingScreen from "../../../components/UIComponents/screens/LoadingScreen";
import SelectIcon from "../../../components/UIComponents/icons/Select";
import EditIcon from "../../../components/UIComponents/icons/Edit";
import DataInsight from "../../../components/UIComponents/icons/DataInsight";

const Campaigns = () => {
    const accessToken = sessionStorage.getItem("OSACCESS");
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchCampaigns = async () => {
        try{
            const response = await fetch(`https://api.onesurvey.de/v1/campaigns`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok){
                const text = await response.text();
                console.log(text);
                throw new Error(text);
            }
            const data = await response.json();
            setCampaigns(data);
            setLoading(false);

        }catch (error){
            setLoading(false);
            setError(true);
        }
    }

    useEffect(()=>{
        fetchCampaigns();
    }, [])


    if (error){
        return (
            <div>
                <Header />
                <div className="app-container">
                    <span className="app-breadcrumbs">
                        <a href='/'>Home &gt;</a>
                        <a href={`/campaigns/`}> OneSurvey Campaigns</a>
                    </span>
                    <h1>OneSurvey Campaigns</h1>
                    <p>Es wurden keine Kampagnen gefunden. <a href="/campaign-setup">Jetzt Kampagne erstellen</a>.</p>
                    <div className="grid-dashboard">
                        <div className="card" id="dashboard-card" id="select-survey">
                            <h2>Mehr erreichen mit OneSurvey Campaigns!</h2>
                            <p>Bekommen Sie mit zielgruppengerichteten Umfrageergebnissen noch mehr Einblicke!</p>
                            <button onClick={()=>navigate('/campaign-setup')} className="btn-primary-white">Jetzt neue Kampagne erstellen</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (loading){
        return (
            <LoadingScreen></LoadingScreen>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/campaigns/`}> OneSurvey Campaigns</a>
                </span>
                <h1>OneSurvey Campaigns</h1>
                <div className="grid-dashboard">
                    {campaigns.map((campaign) => (
                        <div className="card" id="dashboard-card" id={campaign.id} style={{flexGrow: 1, height: "auto"}}>
                            <a href={`/campaigns/${campaign.code}`} style={{textDecoration: "none"}}>
                                <p className="secondaryText">{campaign.code}</p>
                                <h2>{campaign.title}</h2>
                            </a>
                            <p>{campaign.sendCount} E-Mails gesendet | {campaign.visitedCount} Mal aufgerufen</p>
                            <a href={`/campaigns/${campaign.code}`} className="tooltip">
                                <EditIcon size={"30px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Kampagne bearbeiten</span>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href={`/dashboard/${campaign.surveyCode.toUpperCase()}?campaign=${campaign.code}`} className="tooltip">
                                <DataInsight size={"30px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Dashboard</span>
                            </a>
                        </div>
                    ))}
                    <div className="card" id="dashboard-card" id="select-survey">
                        <h2>Mehr erreichen mit OneSurvey Campaigns!</h2>
                        <p>Erhalten Sie noch mehr Einblicke durch zielgruppengerichtete Umfrageergebnisse!</p>
                        <button onClick={()=>navigate('/campaign-setup')} className="btn-primary-white">Jetzt neue Kampagne erstellen</button>
                    </div>
                </div>
            </div>
        </div>
    );


}
export default Campaigns;