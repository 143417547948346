import React from "react";
import {useNavigate} from "react-router-dom";
import '../../App.css';
import WarningIcon from "../../components/UIComponents/icons/Warning";
import PublicFooter from "../../components/PublicFooter";
import PublicHeader from "../../components/PublicHeader";

const Maintenance = () => {

    const navigate = useNavigate();

    return (
        <div>
            <PublicHeader></PublicHeader>
            <div className="app-container">
                <WarningIcon size={"80px"} color={"#3a3a3a"} fill={"none"}></WarningIcon>
                <h1>Wartungsarbeiten im Gange</h1>
                <p>Unsere Plattform wird derzeit gewartet, um Ihnen zukünftig ein besseres Erlebnis bieten zu können.<br/>
                    Während dieser Zeit ist der Zugriff auf die OneSurvey Cloud leider nicht möglich.<br/>
                    Wir informieren Sie per E-Mail, sobald die Systeme wieder verfügbar sind.</p><br/>
                <a type="button" href="https://status.cloudplattform.onesurvey.de">Systemstatus OneSurvey Cloudplattform</a><br/><br/>
                <button type="button" onClick={()=> navigate('/')}>Zurück zur Startseite</button><br/>
            </div>
            <PublicFooter></PublicFooter>
        </div>
    );

}

export default Maintenance;
