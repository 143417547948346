import React, {useEffect, useState} from "react";
import './Checkbox.css';

export default function Checkbox ({ field, value, onChange, fieldOptions }) {

    const [selectedOptions, setSelectedOptions] = useState(value || "");
    const [selectedTitles, setSelectedTitles] = useState("");

    useEffect(() => {
        if (selectedOptions) {
            const selected = selectedOptions.toString();
            const valuesTitles = selected.split(',');
            setSelectedTitles(valuesTitles);
        } else {
            setSelectedTitles([]);
        }
    },[selectedOptions]);

    const handleCheckboxChange = (title) => {
        const isSelected = selectedTitles.includes(title);

        // Titel hinzufügen oder entfernen
        const newTitles = isSelected
            ? selectedTitles.filter(t => t !== title) // Entfernen
            : [...selectedTitles, title]; // Hinzufügen

        setSelectedTitles(newTitles);
        onChange({ target: { value: newTitles.join(',') } });
    };

    return (
        <div>
            {fieldOptions.map(option => (
                <div className="survey-checkbox-element">
                    <label className="survey-checkbox-label" key={option.id}>
                        <input
                            className="survey-checkbox-input"
                            type="checkbox"
                            value={option.id}
                            checked={selectedTitles.includes(option.title)}
                            onChange={() => {handleCheckboxChange(option.title)}}
                        />{option.title}
                    </label>
                </div>
            ))}
        </div>
    );
}
