import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../components/UIComponents/tools/Toast";
import {QRCodeSVG} from 'qrcode.react';

const MFASetup = () => {
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [MFAactivated, setMFAactivated] = useState(false)
    const [totpData, setTotpData] = useState();
    const [error, setError] = useState('');
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [setupStep, setSetupStep] = useState(0);
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useNavigate();

    const setupTotp = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: null
            });

            if (!response.ok) {
                const errorData = await response.text();
                showToast(errorData, 'error');
                throw new Error(errorData || 'Failed to set up TOTP.');
            }

            const data = await response.json();
            setTotpData(data);
            setSetupStep(1);
        } catch (err) {
            setError(err.message);
        }
    };

    const activateTotp = async () => {
        try {
            const activationData = {
                TestSession: totpData.sessionToken,
                Code: verificationCode
            };

            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup/activation', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(activationData)
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                if (errorMessage === "Totp Code not valid."){
                    showToast("TOTP Code falsch.", "error");
                }
                else if (errorMessage === "No TOTP-Code given."){
                    showToast("Bitte geben Sie Ihren TOTP-Code ein.", "error");
                }
                else{
                    throw new Error(errorMessage || 'Failed to deactivate up TOTP.');
                }
            }
            else{
                showToast("Zwei Faktor Authentifizierung wurde erfolgreich eingerichtet.", "success");
                setSetupStep(0);
                setMFAactivated(true);
            }
        } catch (err) {
            setError(err.message);
            showToast(err.message, "error");

        }
    };

    const deactivateTotp = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup/deactivate', {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: null
            });

            if (!response.ok) {
                const errorData = await response.text();
                showToast(errorData, 'error');
                throw new Error(errorData || 'Failed to deactivate up TOTP.');
            }
            setTotpData(null);
            setMFAactivated(false);
            showToast('TOTP wurde deaktiviert.', 'success');
        } catch (err) {
            setError(err.message);
        }
    };

    const validateStatus = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup/validate', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setMFAactivated(true);
            }

        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        validateStatus();
    }, []);

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    const handleNextStep = () => {
        if (setupStep < 3) {
            setSetupStep(setupStep + 1);
        }
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/mfa-setup`}> MFA Einstellungen</a>
                </span>
                <h1>Zwei-Faktor-Authentifizierung einrichten</h1>
                <p>Schützen Sie Ihr Konto mit der Zwei-Faktor-Authentifizierung (2FA). Durch die Aktivierung von 2FA fügen Sie eine zusätzliche Sicherheitsebene hinzu, die sicherstellt, dass nur Sie auf Ihr Konto zugreifen können. Neben Ihrem Passwort benötigen Sie einen einmaligen Code, der über eine Authentifizierungs-App generiert wird. Scannen Sie den QR-Code oder geben Sie das Secret manuell in Ihrer Authentifizierungs-App ein, um die 2FA zu aktivieren.</p>
                <div className="spacer5"></div>
                <p>
                    {MFAactivated ? "Zwei-Faktor-Authentifizierung ist für diesen Account aktiv." : "Zwei-Faktor-Authentifizierung deaktiviert."}
                </p>
                <div className="">
                    {MFAactivated ?
                        <button className="btn-danger" onClick={()=>{deactivateTotp()}}>Zwei-Faktor-Authentifizierung deaktivieren</button>
                        :
                        <button className="btn btn-primary" onClick={() => {setupTotp()}}>Zwei-Faktor-Authentifizierung einrichten</button>
                    }
                    <div className="spacer1"></div>
                    {setupStep === 1 && (
                        <div className="action-card">
                            <h3>Schritt 1: Authenticator App abrufen</h3>
                            <p>Laden Sie eine Authenticator App wie Google Authenticator oder Microsoft Authenticator herunter.</p>
                            <button className="btn btn-primary" onClick={handleNextStep}>Weiter</button>
                        </div>
                    )}

                    {setupStep === 2 && (
                        <div className="action-card">
                            <h3>Schritt 2: QR Code scannen oder Secret eingeben</h3>
                            <p>Scannen Sie den folgenden QR-Code oder geben Sie das Secret manuell in Ihrer Authenticator App ein.</p>
                            {totpData && <QRCodeSVG value={totpData.totpUrl} />}
                            <p>Secret: <strong>{totpData.secret}</strong></p>
                            <p>Name: <strong>OneCoding IDM</strong></p>
                            <button className="btn btn-primary" onClick={handleNextStep}>Weiter</button>
                        </div>
                    )}

                    {setupStep === 3 && (
                        <div className="action-card">
                            <h3>Schritt 3: Code eingeben</h3>
                            <p>Geben Sie den 6-stelligen Code ein, der in Ihrer Authenticator App angezeigt wird, um die 2FA zu aktivieren.</p>
                            <input
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder="6-stelliger Code"
                            />
                            <button className="btn btn-primary" onClick={activateTotp}>2FA aktivieren</button>
                        </div>
                    )}
                </div>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
}

export default MFASetup;

