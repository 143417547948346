import React from 'react';
import './RatingStars.css'; // CSS-Datei für RatingStars

const RatingStars = ({ field, value, onChange }) => {
    return (
        <div className="rating-stars">
            {[...Array(5)].map((_, index) => (
                <span
                    key={index}
                    className={`star ${index < value ? 'filled' : 'empty'}`}
                    onClick={() => onChange({ target: { value: index + 1 } })}
                >
                    ★
                </span>
            ))}
        </div>
    );
};

export default RatingStars;
