import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import './AddField.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";

const AddOption = ({onFieldAdd}) => {
    const { surveyCode } = useParams();
    const { fieldId} = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [fieldTypes, setFieldTypes] = useState([]);
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [fieldError, setFieldError] = useState(false);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchSurveyId();
        fetchFieldOptions(fieldId);
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung des Feldes erfolgreich.");
            } else {
                setFieldError(true);
                console.log("Keine Berechtigung, dieses Feld zu bearbeiten.");
            }
        } catch (error) {
            setFieldError(true);
            console.log(error);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
        }
    };

    const fetchFieldOptions = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setOptions(data);

            if (options.length == 0){
                setMessage('Keine Optionen für diese Feld gefunden.')
            }
            else{
                setMessage('')
            }
        } catch (error) {
            console.log("Fehler beim Abrufen der Optionen:", error);
        }
    };

    const removeOption = async (optionId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchFieldOptions(fieldId);
        } catch (error) {
            console.log("Fehler beim Löschen der Option:", error);
        }
    };


    const handleAddOption = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const optionData = {
                title: formData.get('title'),
                text: "",
                displayed: true,
                nextField: 0
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(optionData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(optionData));
                console.log(response);
                throw new Error('Fehler beim Hinzufügen der Daten');
            }
            else{
                fetchFieldOptions(fieldId);
                console.log("Option added successfully");
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (fieldError) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Das Feld wurde nicht gefunden oder Sie haben keine Berechtigung, dieses zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="add-fields-container">
                <h1>Optionen zu Ihrem Feld hinzufügen</h1>
                <p>Bitte fügen Sie Optionen zu Ihrer Frage hinzu.</p>
                <form onSubmit={handleAddOption}>
                    <div>
                        <label>Text:</label>
                        <input
                            type="text"
                            placeholder="Bitte geben Sie einen Text für diese Option ein."
                            name="title"
                            required
                        />
                    </div>
                    <button className="btn" type="submit">Option hinzufügen</button>
                </form>
                {
                    !onFieldAdd && (<button className="btn-secondary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options`)}>Abbrechen</button>)
                }
                {
                    onFieldAdd && (<button className="btn-primary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/add/checkout`)}>Weiter</button>)
                }
                <p className="message">{message}</p>
                <div className="spacer"></div>
                <h2>Hinzugefügte Optionen:</h2>
                <div className="option-list" id="add-option-item-list">
                    {options.map((option) => (
                        <div className="field" id="fielditem-second" key={option.id}>
                            <p>
                                {option.title}
                            </p>
                            <div className="controls">
                                <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}

export default AddOption