import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/PublicHeader";
import { useNavigate } from "react-router-dom";
import '../../../App.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ResetPassword = () => {
    const { id, code } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [responseText, setResponseText] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [captchaToken, setCaptchaToken] = useState(null);

    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    navigate('/sites/maintenance');
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    };

    useEffect(() => {
        checkMaintenanceMode();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            setResponseText('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
            setResponseStatus("error");
            return;
        }

        const secKey = "57648393454";
        const url = "https://api.onesurvey.de/auth/reset-password";

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ account: id, code, secKey, password })
            });

            const text = await response.text();
            if (text != 'No Reset request or Account was found.'){
                setResponseText("Ihr Passwort wurde erfolgreich abgeändert.");
                setResponseStatus("success");
            }else{
                setResponseText("Diese Anfrage ist nicht mehr gültig.");
                setResponseStatus("error");
            }

        } catch (error) {
            setResponseText(`Error: ${error.message}`);
            setResponseStatus("error");
        }
    };

    const onCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div className="app-send-reset-password">
            <div className="page-container">
                <img
                    src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                    width="250px"
                    alt="OneSurvey Logo"
                />
                <h1>Ihr Passwort zurücksetzen</h1>
                <p>Bitte geben Sie Ihr neues Passwort ein.</p>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="password">Neues Passwort:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="******"
                            required
                        />
                    </div>
                    <HCaptcha
                        sitekey="c3122632-8252-4e70-a38c-3f2857769e9c"
                        onVerify={onCaptchaVerify}
                    />
                    <button type="submit">Passwort zurücksetzen</button>
                </form>
                <div className="spacer"></div>
                <a href="https://app.onesurvey.de">Zurück zur Anmeldung</a>
                <div className="spacer"></div>
                {responseText && <div className={`response ${responseStatus}`}>{responseText}</div>}
            </div>
        </div>
    );

}

export default ResetPassword;
