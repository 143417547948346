import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import '../../App.css';
import WarningIcon from "../../components/UIComponents/icons/Warning";
import InfoIcon from "../../components/UIComponents/icons/Information";

const Datenschutz = () => {

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <div className="">
                    <h1>Datenschutz</h1>
                </div>
                <div className="spacer"></div>
                <div className="action-card">
                    <h1>Allgemeine Hinweise</h1>
                    <p>Der Schutz Ihrer Daten ist für uns bei der OneCoding von oberster Priorität. Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Webseiten und Apps besuchen und verwenden. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
                </div>
                <div className="spacer"></div>
                <div className="action-card">
                    <h1>Datenerfassung</h1>
                    <h2>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h2>
                    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
                    <div className="spacer5"></div>

                    <h2>Wie erfassen wir Ihre Daten?</h2>
                    <p>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie bei der Verwendung unserer Dienste an uns übermitteln. Zum Beispiel mit der Registration eines Accounts, oder das Erstellen einer neuen Umfrage.
                        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                    </p>
                    <div className="spacer5"></div>

                    <h2>Wofür nutzen wir Ihre Daten?</h2>
                    <p>
                        Ein Teil der Daten wird erhoben, um Ihnen eine fehlerfreie Bereitstellung unserer Dienste zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                    </p>
                    <div className="spacer5"></div>

                    <h2>Welche Rechte haben Sie bezüglich Ihrer Daten?</h2>
                    <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                    </p>
                    <div className="spacer5"></div>

                    <h2>Analyse-Tools und Tools von Drittanbietern</h2>
                    <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen. Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</p>
                    <div className="spacer5"></div>

                    <h1>Hosting</h1>
                    <p>Wir hosten unsere Webseite, sowie unsere Webportale und Anwendungen auf eigenen Servern. Für das Hosting verantwortlich:</p>
                    <p>
                        <strong>OneCoding Software Rottenbiller Schenk GbR</strong><br/>
                        Virngrundstraße 19<br/>
                        73486 Adelmannsfelden<br/>
                        <br/>
                        E-Mail: <a href="mailto:info@onecoding.de">info@onecoding.de</a><br/>
                        Tel: <a href="www.onecodingde">www.onecoding.de</a><br/>
                        <br/>
                        Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf eigenen Servern gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe, Umfragedaten und sonstige Daten, die über unsere Webseite und Webanwendungen generiert werden, handeln.

                        Das Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.

                        Wir werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung unserer Leistungspflichten erforderlich ist.
                    </p>

                </div>
                <div className="spacer"></div>
                <div className="action-card">
                    <h1>Allgemeine Hinweise und Pflichtinformationen</h1>
                    <h2>Datenschutz</h2>
                    <p>
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.

                        Wenn Sie diese Website und unsere Dienste benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.

                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.


                    </p>
                    <div className="spacer5"></div>
                    <h2>Hinweis zur verantwortlichen Stelle</h2>
                    <p>
                        <strong>OneCoding Software Rottenbiller Schenk GbR</strong><br/>
                        Virngrundstraße 19<br/>
                        73486 Adelmannsfelden<br/>
                        <br/>
                        E-Mail: <a href="mailto:info@onecoding.de">info@onecoding.de</a><br/>
                        Tel: <a href="https://www.onecoding.de">www.onecoding.de</a><br/>
                    </p>
                </div>
                <div className="spacer"></div>
                <div className="action-card">
                    <h1>Kontakt</h1>
                    <p>
                        OneCoding Software Rottenbiller Schenk GbR<br/>
                        Abteilung Datenschutz<br/>
                        <br/>
                        E-Mail: <a href="mailto:datenschutz@onesurvey.onecoding.de">datenschutz@onesurvey.onecoding.de</a><br/>
                        Web: <a href="https://www.onecoding.de">www.onecoding.de</a><br/>
                    </p>
                </div>
                <div className="spacer"></div>
                <div className="action-card">
                    <a href="https://onesurvey.de"><img src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-4096.png" width="250px"></img></a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="https://onecoding.de"><img src="/headerlogo-ocs.png" width="252px"></img></a>
                </div>
                <div className="spacer"></div>
                <button className="btn-primary" onClick={() => window.location.href = "/"}>
                    Zurück
                </button>
            </div>
        </div>
    );

}

export default Datenschutz;