import React, {useEffect, useState} from "react";
import './Footer.css';
import '../App.css';
import {useNavigate} from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        const loadUserdata = async () => {

            if (!accessToken) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const account = await response.json();
                    console.log(account);
                    setFirstname(account.firstname);
                    setLastname(account.lastname);
                    setEmail(account.email);
                } else {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            }
        };

        loadUserdata();
    }, [navigate]);

    return (
        <div className="app-footer">
            <div className="footer-container">
                <div className="footer-columns">
                    <div className="footer-column">
                        <h4>{firstname} {lastname}</h4>
                        <ul>
                            <li><a href="/myaccount">Mein Account</a></li>
                            <li><a href="/mysurveys">Meine Umfragen</a></li>
                            <li><a href="/dashboard">Dashboard</a></li>
                            <li><a href="/logout">Abmelden</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Service</h4>
                        <ul>
                            <li><a href="/myaccount/subscriptions">Meine Verträge</a></li>
                            <li><a href="https://onesurvey.de/kontakt">Kontakt</a></li>
                            <li><a href="https://onesurvey.de/support">Support & Hilfebereich</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Unternehmen</h4>
                        <ul>
                            <li><a href="https://onecoding.de/ueber-uns/">Über uns</a></li>
                            <li><a href="https://onecoding.de/agb">AGB</a></li>
                            <li><a href="/datenschutz">Datenschutz</a></li>
                            <li><a href="/impressum">Impressum</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4></h4>
                        <div className="footer-logo">
                            <a href="https://onecoding.de"><img src="/headerlogo-ocs-white.png" alt="OneCoding Software" /></a>
                        </div>
                    </div>
                </div>
                <p id="copyright">© 2024 OneCoding Software Rottenbiller Schenk GbR</p>
            </div>

        </div>
    );
}

export default Footer;
