import React, {useEffect, useState} from "react";
import '../../../../../App.css';
import '../SurveyDashboard.css';
import {Bar,Pie, Doughnut} from "react-chartjs-2";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS, Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(LineElement, ArcElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, BarElement, Filler);
ChartJS.register(ChartDataLabels);

const FieldComponent = ({field, key, startDate, endDate, campaign}) => {
    const [fieldData, setFieldData] = useState(null);
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchFieldData = async () => {
            let endpoint = '';
            switch (field.type) {
                case 1:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/textfield/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                case 2:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/textarea/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                case 3:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/ratingstars/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                case 4:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/checkbox/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                case 5:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/dropdown/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                case 8:
                    endpoint = `https://api.onesurvey.de/v1/graph/dashboard/average-value/npsscoring/${field.id}?startDate=${startDate}&endDate=${endDate}&campaign=${campaign}`;
                    break;
                default:
                    return;
            }

            try {
                const response = await fetch(endpoint, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) throw new Error("Fehler beim Abrufen der Felddaten");

                const data = await response.json();
                setFieldData(data);

            } catch (error) {
                console.error(error);
            }
        };

        fetchFieldData();
    }, [field.id, field.type, startDate, endDate, campaign]);

    if (!fieldData) return null;

    switch (field.type) {
        //Textfield
        case 1:

            if (fieldData.length === 0) {
                return (
                    <div className="card" id="dashboard-card">
                        <p className="secondaryText">Keine Daten zum Anzeigen vorhanden</p>
                        <h3>{field.title}</h3>
                    </div>
                );
            }

            return (
                <div className="grid-dashboard-card" id="dashboard-card">
                    <div className="badge-grid">
                        {fieldData.map((data) => (
                            <span className="text-badge" id={data.id}>{data.data}</span>
                        ))}
                    </div>
                    <h3>{field.title}</h3>
                </div>
            );

        //Textarea
        case 2:

            if (fieldData.data === null) {
                return (
                    <div className="grid-dashboard-card" id="dashboard-card">
                        <p className="secondaryText">Keine Daten zum Anzeigen vorhanden</p>
                        <h3>{field.title}</h3>
                    </div>
                );
            }

            return (
                <div className="grid-dashboard-card" id="dashboard-card" style={{height: "auto"}}>
                    <p style={{fontStyle: "italic"}}>"{fieldData.data}"</p>
                    <p className="secondaryText">Zufälliger Eintrag</p>
                    <h3>{field.title}</h3>
                </div>
            );

        //Ratingstars
        case 3:

            if (fieldData.length === 0) {
                return (
                    <div className="grid-dashboard-card" id="dashboard-card">
                        <p className="secondaryText">Keine Daten zum Anzeigen vorhanden</p>
                        <h3>{field.title}</h3>
                    </div>
                );
            }

            const ratingsCount = [0, 0, 0, 0, 0];

            fieldData.forEach(item => {
                const rating = parseInt(item.data, 10);
                ratingsCount[rating - 1] += 1;
            });

            const barChartData = {
                labels: ['1 Stern', '2 Sterne', '3 Sterne', '4 Sterne', '5 Sterne'],
                datasets: [
                    {
                        label: 'Anzahl der Bewertungen',
                        data: ratingsCount,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                        ],
                        borderWidth: 1,
                        datalabels: {
                            display: false,
                        },
                    },

                ],
            };

            return (
                <div className="grid-dashboard-card" id="dashboard-card">
                    <Bar
                        data={barChartData}
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                    <h3>{field.title}</h3>
                </div>
            );

        //Checkbox
        case 4:
            if (fieldData.length === 0){
                return(
                    <div className="grid-dashboard-card" id="dashboard-card">
                        <p className="secondaryText">Keine Daten zum Anzeigen vorhanden</p>
                        <h3>{field.title}</h3>
                    </div>
                );
            };

            const labels = fieldData.map(item => item.service);
            const counts = fieldData.map(item => item.count);

            const pieChartData = {
                labels: labels,
                datasets: [
                    {
                        data: counts,
                        backgroundColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 3,
                        cutout: '70%',
                    },
                ]
            };

            const pieChartOptions = {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    animation: {
                        duration: 1500,
                        easing: 'easeOutBounce',
                    },
                    hover: {
                        mode: 'nearest',
                        animationDuration: 800,
                    },
                    elements: {
                        arc: {
                            hoverBorderWidth: 3,
                        },
                    },
                    responsive: true,
                }
            };

            return (
                <div className="grid-dashboard-card" id="dashboard-card">
                    <Doughnut data={pieChartData} options={pieChartOptions} style={{width: 'auto'}}>
                    </Doughnut>
                    <h3>{field.title}</h3>
                </div>
            );

        // Dropdown
        case 5:
            if (fieldData.length === 0) {
                return (
                    <div className="grid-dashboard-card" id="dashboard-card">
                        <p className="secondaryText">Keine Daten zum Anzeigen vorhanden</p>
                        <h3>{field.title}</h3>
                    </div>
                );
            }

            const dropdownLabels = fieldData.map(item => item.service);
            const dropdownCounts = fieldData.map(item => item.count);

            const dropdownPieChartData = {
                labels: dropdownLabels,
                datasets: [
                    {
                        data: dropdownCounts,
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                        datalabels: {
                            display: false,
                        },
                    },
                ],
            };

            return (
                <div className="grid-dashboard-card" id="dashboard-card">
                    <Pie data={dropdownPieChartData}/>
                    <h3>{field.title}</h3>
                </div>
            );

        case 8:
            return(
                <div className="grid-dashboard-card" id="dashboard-card" style={{flexGrow: 3}}>
                    <p>Net Promoter Score</p>
                    <h1>
                        {fieldData.nps.toFixed(2)}
                        {fieldData.nps.toFixed(2) > 75 ? " (Exzellent)" :
                            fieldData.nps.toFixed(2) > 50 ? " (Sehr gut)" :
                                fieldData.nps.toFixed(2) > 30 ? " (Gut)" :
                                    fieldData.nps.toFixed(2) > 0 ? " (Verbesserungsfähig)" :
                                        fieldData.nps.toFixed(2) > -25 ? " (Schwach)" :
                                            " (Sehr schlecht)"}
                    </h1>
                    <p>Promoters: {fieldData.promoters || "Keine Daten vorhanden"} <br/><span className="secondaryText">(9-10 von 10 Punkten)</span></p>
                    <p>Passives: {fieldData.passives || "Keine Daten vorhanden"} <br/><span className="secondaryText">(7-8 von 10 Punkten)</span></p>
                    <p>Detractors: {fieldData.detractors || "Keine Daten vorhanden"} <br/><span className="secondaryText">(0-6 von 10 Punkten)</span></p>
                    <h3>{field.title}</h3>
                </div>
            );

        default:
            return(
                <div className="grid-dashboard-card" id="dashboard-card">
                    <p>Fehler beim Abrufen der Daten.</p>
                    <p className="secondaryText">Error: FieldType Missmatch {field.type}</p>
                </div>
            );
    }
}

export default FieldComponent