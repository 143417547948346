import React, { useState, useEffect } from 'react';
import './Toast.css'; // Pfad zu deinem Toast-CSS

const Toast = ({ message, type, duration = 3000, onClose }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);

        const timer = setTimeout(() => {
            setVisible(false);
            setTimeout(onClose, 300);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div>
            <div className={`toast ${type} ${visible ? 'show' : ''}`}>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default Toast;
