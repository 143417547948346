import React, {useState, useEffect} from "react";
import CustomHeader from "../../components/survey/CustomHeader";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "../../components/inputForms/TextField";
import TextArea from "../../components/inputForms/TextArea";
import RatingStars from "../../components/inputForms/RatingStars";
import Checkbox from "../../components/inputForms/Checkbox";
import Dropdown from "../../components/inputForms/Dropdown";
import Header from "../../components/Header";
import SurveyHeader from "../../components/SurveyHeader";



const SurveyCheckout = () => {

    let { id } = useParams();

    const navigate = useNavigate();
    const [surveyInfo, setSurveyInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                // Fetch survey info
                const surveyInfoResponse = await fetch(`https://api.onesurvey.de/opensurvey/v1/surveyinfo/${id}`);
                if (!surveyInfoResponse.ok) {
                    throw new Error('Network response was not ok for survey info');
                }
                const surveyInfoResult = await surveyInfoResponse.json();
                setSurveyInfo(surveyInfoResult);

                setLoading(false);

            } catch (error) {
                setError(error);
                console.log(`Error ${error}`);
                setLoading(false);
            }
        };

        fetchSurveyData();

    }, [id]);

    if (loading) {
        return (
            <div>
                <SurveyHeader></SurveyHeader>
                <div className="app-container">
                    <div>Loading...</div>
                </div>
            </div>
        )
    }

    if (error) {
        document.title = '404 Umfrage wurde nicht gefunden - OneSurvey';
        return (
            <div>
                <SurveyHeader></SurveyHeader>
                <div className="app-container">
                    <h1>404 - Not found</h1>
                    <p>Die gewünschte Umfrage mit der ID {id} konnte leider nicht gefunden werden.</p>
                    <button onClick={()=> navigate('/s')}>zurück zur Startseite</button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <CustomHeader logoUrl={surveyInfo.frontendLogo} headerBackground={surveyInfo.headerBackground}></CustomHeader>
            <div className="survey-container" style={{
                backgroundImage: `url(${surveyInfo.siteBackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="survey" style={{ background: `rgb(255,255,255,0.8)`}}>
                    <h1>Vielen Dank - Ihre Eingaben wurden erfolgreich übermittelt!</h1>
                    <p>Ihre Umfrage "{surveyInfo.title}" wurde erfolgreich an uns übermittelt. Vielen Dank, dass Sie sich Zeit genommen haben, diese Umfrage zu beantworten!</p>
                    <hr/>
                    <a href={surveyInfo.linkHome || "https://onesurvey.de"}>Zurück zur Webseite</a><br/>
                    <a href={surveyInfo.linkImpressum || "/impressum"}>Impressum</a><br/>
                    <a href={surveyInfo.linkPrivacy || "/privacy"}>Datenschutz</a>
                </div>
            </div>
        </div>
    );
}

export default SurveyCheckout;