import React, { useState } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../components/UIComponents/tools/Toast";

const ChangePassword = () => {

    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [data, setData] = useState({
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
    });

    if (!accessToken) {
        navigate('/login');
        return null;
    }

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                oldPassword: data.oldPassword,
                newPassword1: data.newPassword1,
                newPassword2: data.newPassword2,
            };

            if (!validatePassword(data.newPassword1)) {
                showToast('Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.', 'error');
                return;
            }

            const response = await fetch(`https://api.onesurvey.de/v1/account/change-password`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                const text = await response.text();
                switch (text) {
                    case "Passwords are not equal.":
                        showToast('Die neuen Passwörter stimmen nicht überein.', 'error');
                        break;
                    case "No Passwords given.":
                        showToast('Bitte geben Sie ein sicheres neues Passwort an.', 'error');
                        break;
                    case "No Auth":
                        showToast('Das aktuelle Passwort stimmt nicht mit Ihrem jetzigen Passwort überein.', 'error');
                        break;
                    default:
                        showToast(text, 'error');
                }
            } else {
                showToast('Passwort erfolgreich abgeändert.', 'success');
                setTimeout(()=>{navigate('/myaccount')}, 1000);
            }
        } catch (error) {
            setError(error);
            console.error("Error:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Login & Sicherheit</h1>
                    <p>Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.</p>
                </div>
            </div>
        );
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div className="password-change-page">
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/change-password`}> Passwort ändern</a>
                </span>
                <h1>Ihr Passwort ändern</h1>
                <p>Vergeben Sie ein neues sicheres Passwort.</p>
                <form onSubmit={handleSubmit} className="form-password">
                    <div className="form-group">
                        <label>Bitte geben Sie Ihr bestehendes Passwort ein:</label>
                        <input
                            type="password"
                            name="oldPassword"
                            onChange={handleChange}
                            placeholder="*****"
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Bitte geben Sie Ihr neues Passwort ein:</label>
                        <input
                            type="password"
                            name="newPassword1"
                            onChange={handleChange}
                            placeholder="*****"
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Bitte wiederholen Sie Ihr neues Passwort:</label>
                        <input
                            type="password"
                            name="newPassword2"
                            onChange={handleChange}
                            placeholder="*****"
                            required
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <button type="submit" className="btn-primary">Passwort ändern</button>
                        <button className="btn-secondary" onClick={()=>navigate('/myaccount')}>Abbrechen</button>
                    </div>
                </form>
                {message && <p className="form-message">{message}</p>}
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default ChangePassword;
