import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import "../../../../App.css";
import SelectIcon from "../../../../components/UIComponents/icons/Select";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import {useNavigate} from "react-router-dom";

const SelectSurvey = () => {

    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchSurveyInfo();
    }, []);

    useEffect(() => {
        if (surveys.length === 1) {
            navigate(`/dashboard/${surveys[0].code}`);
        }
    }, [surveys]);


    const fetchSurveyInfo = async () => {
        try {
            const response = await fetch("https://api.onesurvey.de/v1/admin/surveys", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error("Network response was not ok for survey info");
            }

            const surveyInfoResult = await response.json();
            setSurveys(surveyInfoResult);
            setLoading(false);
        } catch (error) {
            setError(error);
            console.log(`Error ${error}`);
            setLoading(false);
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Meine Umfragen</h1>
                    <p>Es wurden keine Umfragen gefunden. <a href="/setup">Jetzt Umfrage erstellen</a>.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/dashboard/`}> Dashboard &gt;</a>
                    <a href={`/panel/select-survey`}> Umfrage auswählen</a>
                </span>
                <h1>Bitte wählen Sie eine Umfrage aus</h1>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Bitte wählen Sie eine Umfrage aus, zu der Sie mehr Informationen zu den Auswertungen haben möchten.</p>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="grid-list">
                    {surveys.map((survey) => (
                        <div key={survey.id} className="card">
                            <a href={`/dashboard/${survey.code}`} style={{textDecoration: "none"}}>
                                <h3 style={{minHeight: "80px"}}>{survey.title}</h3>
                                <p className="secondaryText" id="sharinginformation">{survey.isSurveyShared ? "Mit Ihnen geteilt" : ""}</p>
                            </a>
                            <a href={`/dashboard/${survey.code}`} class="tooltip">
                                <SelectIcon size={"42px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Umfrage auswählen</span>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="spacer"></div>
                <button className="btn-primary" onClick={() => window.location.href = "/dashboard"}>
                    Zurück zur Gesamtübersicht
                </button>
            </div>
        </div>
    );
};

export default SelectSurvey;
