import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import './EditFields.css';
import EditIcon from "../../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../../components/UIComponents/icons/Remove";
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../../../components/UIComponents/tools/Toast";

const EditField = () => {
    const { surveyCode } = useParams();
    const { fieldId} = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [options, setOptions] = useState([]);
    const [currentFieldOptions, setCurrentFieldOptions] = useState(null);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [error, setError] = useState(false);
    const [fieldError, setFieldError] = useState(false);
    const [fieldSettings, setFieldSettings] = useState({
        position: 0,
        title: 'TEST',
        description: '',
        hintText: '',
        visible: false
    });

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchFieldOptions(fieldId);
        fetchFieldData(fieldId);
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                showToast('Keine gültige OneSurvey-Subscription.', 'error');
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                showToast('Sie haben keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie die Seite neu.', 'error');
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung des Feldes erfolgreich.");
            } else {
                setFieldError(true);
                showToast('Sie haben keine Berechtigung diese Frage zu bearbeiten.', 'error');
                console.log("Keine Berechtigung, dieses Feld zu bearbeiten.");
            }
        } catch (error) {
            setFieldError(true);
            console.log(error);
        }
    };

    const fetchFieldOptions = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setOptions(data);
            if (response.ok){
                setCurrentFieldOptions(true);
            }
        } catch (error) {
            console.log("Fehler beim Abrufen der Optionen:", error);
        }
    };

    const fetchFieldData = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldSettings(data);
        } catch (error) {
            showToast('Fehler beim Abrufen des Feldes.', 'error');
            console.log("Fehler beim Abrufen des Feldes:", error);
        }
    };

    const handleEditField = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const editData = {
                position: fieldSettings.position,
                title: fieldSettings.title,
                description: fieldSettings.description,
                hintText: fieldSettings.hintText,
                visible: fieldSettings.visible
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editData)
            });

            if (!response.ok) {
                console.log(response);
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                showToast('Frage erfolgreich abgeändert.', 'success');
                setTimeout(()=>{navigate(`/mysurveys/${surveyCode}/fields`);}, 1000)
            }
        } catch (error) {
            console.log(`Error ${error}`);
            showToast('Fehler beim ändern der Frage. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (fieldError) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Das Feld wurde nicht gefunden oder Sie haben keine Berechtigung, dieses zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            'visible': e.target.checked,
        }));
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="edit-fields-container">
                <h1>Bestehendes Feld ändern</h1>
                <p>Bearbeiten Sie die Eigenschaften dieses Feldes.</p>
                <form onSubmit={handleEditField}>
                    <div className="spacer"></div>
                    <div>
                        <label className="checkbox-label">
                            <input
                                className="checkbox-input"
                                type="checkbox"
                                checked={fieldSettings.visible}
                                onChange={handleCheckboxChange}
                            />
                            Feld in Umfrage ersichtlich.
                        </label>
                    </div>
                    <div className="spacer"></div>
                    <div>
                        <label>Titel:</label>
                        <input
                            type="text"
                            placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                            value={fieldSettings.title}
                            name="title"
                            onChange={handleInputChange}
                            required
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Beschreibung:</label>
                        <textarea
                            className="textarea"
                            placeholder="Bitte bewerten Sie unsere Produkte & Dienstleistungen"
                            onChange={handleInputChange}
                            name="description"
                            value={fieldSettings.description}
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Hinweistext:</label>
                        <input
                            type="text"
                            placeholder="Dieser Text erscheint bei Textfeldern als Hinweis (vor der Eingabe)"
                            onChange={handleInputChange}
                            name="hintText"
                            value={fieldSettings.hintText}
                        />
                    </div>
                    <button className="btn btn-primary" type="submit">Änderungen abspeichern</button>
                </form>
                {
                    currentFieldOptions && (<button className="btn-primary" onClick={()=>navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options`)}>Optionen bearbeiten</button>)
                }
                <button className="btn-secondary" onClick={() => navigate(`/mysurveys/${surveyCode}/fields/`)}>Abbrechen</button>
                <p className="message">{message}</p>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );

}

export default EditField