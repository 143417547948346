import React, { useState, useEffect } from 'react';
import NPSSelector from './NPSSelector';

const NPSParent = ({ value, onChange }) => {
    const [npsValue, setNpsValue] = useState(value || null);

    useEffect(() => {
        if (value == 0){
            setNpsValue(null);
        }
    }, []);

    const onChangeNPSValue = (event) => {
        setNpsValue(event.target.value)
        onChange({ target: { value: event.target.value } })
    }

    return (
        <div>
            <NPSSelector value={npsValue} onChange={(e) => onChangeNPSValue(e)} />
        </div>
    );
};

export default NPSParent;
