import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import CheckIcon from "../../../../../components/UIComponents/icons/Check";

const UpgradeCheckout = () => {

    const navigate = useNavigate();

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <CheckIcon size={"75px"} fill={"none"} color={"yellowgreen"}></CheckIcon>
                <h1>Vielen Dank für Ihr Upgrade!</h1>
                <p>Ihr Abonnement wurde erfolgreich erweitert und startet automatisch am Tag nach Ablauf des aktuellen Abonnements. Eine Bestätigung und weitere Informationen erhalten Sie in Kürze per E-Mail. Bei Fragen stehen wir Ihnen gern zur Verfügung.</p>
                <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                    Zurück zur Übersicht
                </button>
            </div>
        </div>
    );
}

export default UpgradeCheckout;