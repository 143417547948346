import React from "react";
import './TextArea.css';

export default function TextArea ({ field, value, onChange }) {

    return <textarea
                className="textarea-survey"
                placeholder={field.hintText}
                value={value}
                onChange={onChange}
            />;

}
