import React, { useState } from 'react';
import './EmailInput.css'; // Optional: Für benutzerdefinierte Styles

const EmailInput = ({ onEmailsChange }) => {
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
            e.preventDefault();
            const email = inputValue.trim();
            if (email && isValidEmail(email)) {
                setEmails([...emails, email]);
                setInputValue('');
                onEmailsChange([...emails, email]);
            }
        }
    };

    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((email, i) => i !== index));
        const newEmails = emails.filter((email, index) => index !== index);
        onEmailsChange(newEmails);
    };

    const isValidEmail = (email) => {
        // Regex für einfache E-Mail-Validierung
        return /\S+@\S+\.\S+/.test(email);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteText = e.clipboardData.getData('text');
        const emailsToAdd = pasteText.split(',').map(email => email.trim());
        const validEmails = emailsToAdd.filter(email => isValidEmail(email));
        if (validEmails.length) {
            setEmails([...emails, ...validEmails]);
        }
    };

    return (
        <div className="email-input-container">
            <div className="email-tags">
                {emails.map((email, index) => (
                    <div className="email-tag" key={index}>
                        {email}
                        <span className="remove-tag" onClick={() => handleRemoveEmail(index)}>
              &times;
            </span>
                    </div>
                ))}
                <input
                    type="text"
                    value={inputValue}
                    placeholder="E-Mail-Adresse eingeben..."
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onPaste={handlePaste}
                    className="email-input"
                />
            </div>
        </div>
    );
};

export default EmailInput;
