import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Header";
import '../../../../App.css';
import {almostWhole} from "chart.js/helpers";

const AddSurvey = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [linkHome, setLinkHome] = useState("");
    const [linkImpressum, setLinkImpressum] = useState("");
    const [linkPrivacy, setLinkPrivacy] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    useEffect(() => {
        SubscriptionCheck();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const surveyData = {
            title,
            description,
            status: false,
            isSecured: false,
            linkHome,
            linkImpressum,
            linkPrivacy
        };

        try {
            const response = await fetch('https://api.onesurvey.de/v1/admin/surveys/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(surveyData)
            });

            if (response.ok) {
                const newSurvey = await response.text();
                setMessage("Umfrage erfolgreich erstellt!");
                navigate(`/setup/${newSurvey}/design`, { state: { code: newSurvey.code } });
            } else {
                const errorText = await response.text();
                setMessage(`Fehler: ${errorText}`);
            }
        } catch (error) {
            setMessage(`Fehler: ${error.message}`);
        }
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <h1>Erstellen Sie eine neue Umfrage</h1>
                <p>Bitte geben Sie Ihrer neuen Umfrage einen aussagekräftigen Titel und eine Beschreibung. Bitte geben Sie aus rechtlichen Gründen noch einen Link zu Ihrer Webseite, Ihrem Impressum und Ihren Datenschutzbedingungen an.</p>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Titel:</label>
                        <input type="text"
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                               placeholder={"Musterumfrage der Muster GmbH"}
                               required />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Beschreibung:</label>
                        <br/>
                        <textarea value={description}
                                  className="textarea"
                                  onChange={(e) => setDescription(e.target.value)}
                                  placeholder={"Bitte geben Sie eine kurze Beschreibung Ihrer Umfrage an."}
                                  required>
                        </textarea>
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zur Startseite:</label>
                        <input type="text"
                               value={linkHome}
                               onChange={(e) => setLinkHome(e.target.value)}
                               placeholder="https://ihre-webseite.de"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zum Impressum:</label>
                        <input type="text"
                               value={linkImpressum}
                               onChange={(e) => setLinkImpressum(e.target.value)}
                               placeholder="https://ihre-webseite.de/impressum"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div>
                        <label>Link zur Datenschutzerklärung:</label>
                        <input type="text" value={linkPrivacy}
                               onChange={(e) => setLinkPrivacy(e.target.value)}
                               placeholder="https://ihre-webseite.de/datenschutz"
                        />
                    </div>
                    <div className="spacer3"></div>
                    <button className="btn-primary" type="submit">Umfrage erstellen</button>
                    <button onClick={()=>navigate('/mysurveys')} className="btn-secondary" type="submit">Abbrechen</button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default AddSurvey;