import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Registration.css';
import CheckIcon from "../../../components/UIComponents/icons/Check";
import VerifyEmailIcon from "../../../components/UIComponents/icons/VerifyEmail";

const RegisterCheckout = () => {
    const navigate = useNavigate();

    return (
        <div className="app-registration">
            <div className="registration">
                <div className="registration-container">
                    <div className="registration-wrapper">
                        <img
                            src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                            width="250px"
                            alt="OneSurvey Logo"
                        />
                        <p></p>
                        <VerifyEmailIcon size={"60px"} fill={"none"} color={"#3a3a3a"}></VerifyEmailIcon>
                        <h2>Bitte verifizieren Sie Ihre E-Mail-Adresse</h2>
                        <p>Sie erhalten noch eine E-Mail mit Verifizierungslink. Bitte verifizieren Sie Ihre E-Mail-Adresse.</p>
                        <a href="https://app.onesurvey.de/resend-verify-code">Code nicht erhalten?</a>&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                        <a href="/login">Zurück zur Anmeldung</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterCheckout;
