import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import './EditField.css';
import EditIcon from "../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../components/UIComponents/icons/Remove";
import CloseIcon from "../../../../components/UIComponents/icons/Close";
import CheckIcon from "../../../../components/UIComponents/icons/Check";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import Toast from "../../../../components/UIComponents/tools/Toast";

const Setup_EditField = () => {
    const { surveyCode } = useParams();
    const { fieldId} = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [options, setOptions] = useState([]);
    const [currentFieldOptions, setCurrentFieldOptions] = useState(null);
    const [message, setMessage] = useState("");
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [error, setError] = useState(false);
    const [fieldError, setFieldError] = useState(false);
    const [fieldSettings, setFieldSettings] = useState({
        position: 0,
        title: '',
        description: '',
        hintText: '',
        visible: false
    });

    const [listOptions, setListOptions] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [addOption, setAddOption] = useState(false);
    const [currentOption, setCurrentOption] = useState(null);

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchFieldOptions(fieldId);
        fetchFieldData(fieldId);
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                showToast('Keine gültige OneSurvey-Subscription.', 'error');
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                showToast('Sie haben keine Berechtigung diese Umfrage zu bearbeiten. Bitte laden Sie die Seite neu.', 'error');
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung des Feldes erfolgreich.");
            } else {
                setFieldError(true);
                showToast('Sie haben keine Berechtigung diese Frage zu bearbeiten.', 'error');
                console.log("Keine Berechtigung, dieses Feld zu bearbeiten.");
            }
        } catch (error) {
            setFieldError(true);
            console.log(error);
        }
    };

    const fetchFieldOptions = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setOptions(data);
            if (response.ok){
                setCurrentFieldOptions(true);
            }
        } catch (error) {
            console.log("Fehler beim Abrufen der Optionen:", error);
        }
    };

    const fetchFieldData = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldSettings(data);
        } catch (error) {
            showToast('Fehler beim Abrufen des Feldes.', 'error');
            console.log("Fehler beim Abrufen des Feldes:", error);
        }
    };

    const handleEditField = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const editData = {
                position: fieldSettings.position,
                title: fieldSettings.title,
                description: fieldSettings.description,
                hintText: fieldSettings.hintText,
                visible: fieldSettings.visible
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editData)
            });

            if (!response.ok) {
                console.log(response);
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                showToast('Frage erfolgreich abgeändert.', 'success');
                setTimeout(()=>{navigate(`/setup/${surveyCode}/add-fields`);}, 1000)
            }
        } catch (error) {
            console.log(`Error ${error}`);
            showToast('Fehler beim ändern der Frage. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const removeOption = async (optionId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchFieldOptions(fieldId);
            showToast(`Option erfolgreich entfernt.`, 'success');
        } catch (error) {
            showToast(`Fehler beim Löschen der Option ${optionId}.`, 'error');
            console.log("Fehler beim Löschen der Option:", error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    if (fieldError) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Das Feld wurde nicht gefunden oder Sie haben keine Berechtigung, dieses zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    //Utils
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };
    const handleCheckboxChange = (e) => {
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            'visible': e.target.checked,
        }));
    };
    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };
    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    //List Options
    const openListOptions = () => {
        setEditOption(false);
        setAddOption(false);
        setListOptions(true);
    };
    const ListOptionsPopup = ({ fieldId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1 id="popuptitle">Optionen bearbeiten</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bearbeiten Sie die Optionen dieses Feldes.</p>
                    <div className="option-list" id="options-item-list">
                        {options.map((option) => (
                            <div className="field" id="fielditem-second" key={option.id}>
                                <p>
                                    {option.title}
                                </p>
                                <div className="controls">
                                    <span onClick={()=>{openEditOption(option.id)}}><EditIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                    <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="add-items">
                        <button className="btn-primary" onClick={()=>{openAddOption();}}>Neue Option hinzufügen</button>
                    </div>
                </div>
            </div>
        );
    };

    //Add Options
    const openAddOption = () => {
        setEditOption(false);
        setListOptions(false);
        setAddOption(true);
    };
    const handleAddOption = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const optionData = {
                title: formData.get('title'),
                text: "",
                displayed: true,
                nextField: 0
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(optionData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(optionData));
                console.log(response);
                showToast(`Option ${optionData.title} konnte nicht hinzugefügt werden. Bitte laden Sie die Seite erneut.`, 'error');
                throw new Error('Fehler beim Hinzufügen der Daten');
            }
            else{
                fetchFieldOptions(fieldId);
                console.log("Option added successfully");
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };
    const AddOptionPopup = ({ fieldId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1 id="popuptitle">Optionen zu Ihrer Frage hinzufügen</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bitte fügen Sie Optionen zu Ihrer Frage hinzu.</p>
                    <form onSubmit={handleAddOption}>
                        <div>
                            <label>Text:</label>
                            <input
                                type="text"
                                placeholder="Bitte geben Sie einen Text für diese Option ein."
                                name="title"
                                required
                            />
                        </div>
                        <button className="btn-primary" type="submit">Option hinzufügen</button>
                    </form>
                    <div className="spacer"></div>
                    <div className="option-list" id="add-option-item-list">
                        {options.map((option) => (
                            <div className="field" id="fielditem-second" key={option.id}>
                                <p>
                                    {option.title}
                                </p>
                                <div className="controls">
                                    <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="btn-secondary" onClick={()=>{openListOptions()}}>Zurück</button>
                </div>
            </div>
        );
    };

    //Edit Option
    const openEditOption = (optionId) => {
        setCurrentOption(optionId)
        setAddOption(false);
        setListOptions(false);
        setEditOption(true);
    };
    const handleEditOption = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const editData = {
                title: formData.get('text'),
                text: formData.get('text'),
                displayed: true,
                nextField: 0
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${currentOption}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(editData));
                console.log(response);
                showToast(`Fehler beim Ändern der Option ${editData.title}.`, 'error');
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                fetchFieldOptions(fieldId);
                console.log("Option updated successfully");
                showToast(`Option ${editData.title} erfolgreich geändert.`, 'success');
                openListOptions();
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };
    const EditOptionPopup = ({ optionId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1 id="popuptitle">Option bearbeiten</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bearbeiten Sie diese Option.</p>
                    <form onSubmit={handleEditOption}>
                        <div className="spacer"></div>
                        <div>
                            <label>Text:</label>
                            <input
                                type="text"
                                placeholder={options.find((option) => option.id === optionId)?.title || 'Geben Sie dieser Option einen aussagekräftigen Titel'}
                                name="text"
                                required
                            />
                            <div className="spacer"></div>
                        </div>
                        <button className="btn-primary" type="submit">Änderung abspeichern</button>
                    </form>
                    <button className="btn-secondary" onClick={()=>openListOptions()}>Zurück</button>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Header />
            <div className="edit-fields-container">
                <h1>Frage ändern</h1>
                <p>Bearbeiten Sie die Eigenschaften dieses Feldes.</p>
                <form onSubmit={handleEditField}>
                    <div className="spacer"></div>
                    <div>
                        <label className="checkbox-label">
                            <input
                                className="checkbox-input"
                                type="checkbox"
                                checked={fieldSettings.visible}
                                onChange={handleCheckboxChange}
                            />
                            Feld in Umfrage ersichtlich.
                        </label>
                    </div>
                    <div className="spacer"></div>
                    <div>
                        <label>Titel:</label>
                        <input
                            type="text"
                            placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                            value={fieldSettings.title}
                            name="title"
                            onChange={handleInputChange}
                            required
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Beschreibung:</label>
                        <br/>
                        <textarea
                            className="textarea"
                            placeholder="Bitte bewerten Sie unsere Produkte & Dienstleistungen"
                            onChange={handleInputChange}
                            name="description"
                            value={fieldSettings.description}
                        />
                        <div className="spacer"></div>
                    </div>
                    <div>
                        <label>Hinweistext:</label>
                        <input
                            type="text"
                            placeholder="Dieser Text erscheint bei Textfeldern als Hinweis (vor der Eingabe)"
                            onChange={handleInputChange}
                            name="hintText"
                            value={fieldSettings.hintText}
                        />
                    </div>
                    <button className="btn btn-primary" type="submit">Änderungen abspeichern</button>
                </form>
                {
                    currentFieldOptions && (<button className="btn-primary" onClick={()=>{openListOptions()}}>Optionen bearbeiten</button>)
                }
                <button className="btn-secondary" onClick={() => navigate(`/setup/${surveyCode}/add-fields`)}>Abbrechen</button>
                <div className="spacer"></div>
            </div>

            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )};

            {listOptions && (
                <ListOptionsPopup
                    fieldId = {fieldId}
                    onClose={() => setListOptions(false)}
                />
            )};

            {addOption && (
                <AddOptionPopup
                    onClose={() => setAddOption(false)}
                />
            )}

            {editOption && (
                <EditOptionPopup
                    optionId = {currentOption}
                    onClose={() => setEditOption(false)}
                />
            )}

        </div>
    );

}

export default Setup_EditField;