import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import "../../../App.css";
import "./MySurveys.css";
import EditIcon from "../../../components/UIComponents/icons/Edit";
import OpenTabIcon from "../../../components/UIComponents/icons/OpenTab";
import DataInsight from "../../../components/UIComponents/icons/DataInsight";
import LoadingScreen from "../../../components/UIComponents/screens/LoadingScreen";
import ShareIcon from "../../../components/UIComponents/icons/Share";
import CloseIcon from "../../../components/UIComponents/icons/Close";
import RemoveIcon from "../../../components/UIComponents/icons/Remove";
import Toast from "../../../components/UIComponents/tools/Toast";
import PieChart from "../../../components/UIComponents/icons/PieChart";
import {useNavigate} from "react-router-dom";

const MySurveys = () => {
    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sharePopupStatus, setSharePopup] = useState(false);
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [messageStatus, setMessageStatus] = useState("green");
    const [existingShares, setExistingShares] = useState(false);
    const [shares, setShares] = useState([]);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        const fetchSurveyInfo = async () => {
            try {
                const response = await fetch("https://api.onesurvey.de/v1/admin/surveys", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok for survey info");
                }

                const surveyInfoResult = await response.json();
                setSurveys(surveyInfoResult);
                setLoading(false);
            } catch (error) {
                setError(error);
                console.log(`Error ${error}`);
                setLoading(false);
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await fetch("https://api.onesurvey.de/v1/admin/roles", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok for survey info");
                }
                const data = await response.json();
                setRoles(data);
            } catch (error) {
                setError(error);
            }
        };

        fetchSurveyInfo();
        fetchRoles();
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <PieChart size={"80px"} color={"#3a3a3a"} fill={"none"}></PieChart>
                    <h1>Es sind noch keine Umfragen vorhanden.</h1>
                    <p>Es wurden keine Umfragen gefunden.</p>
                    <button className="btn btn-primary" onClick={()=>{navigate("/setup")}}>Jetzt Umfrage erstellen!</button>
                </div>
            </div>
        );
    }

    const openSharePopup = async (id) => {
        setCurrentSurvey(id)
        fetchShares(id);
        setSharePopup(true);
    };

    const fetchShares = async (id) => {
        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}/share/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok){
                const text = await response.text();
                if (text === "No Shares found."){
                    setExistingShares(false);
                }
                else{
                    console.log(text);
                    throw new Error('Daten konnten nicht abgerufen werden.');
                }
            }
            else{
                const data = await response.json();
                setShares(data);
                setExistingShares(true);
            }
        }
        catch (error){
            showToast("Bestehende Freigaben konnten nicht abgerufen werden. Bitte laden Sie die Seite erneut.", 'error')
        }
    }

    const handleShare = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            const form = event.target;
            const formData = new FormData(form);
            const shareData = {
                email: formData.get('email'),
                roleId: formData.get('role'),
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${currentSurvey}/share`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(shareData)
            });

            const text = await response.text();
            if (!response.ok) {
                if (text === "Assigning own Account not possible."){
                    showToast("Sie können Ihre Umfrage nicht mit sich selbst teilen.", 'error')
                }
                else if(text === "No Account found."){
                    showToast("Es wurde leider kein zugehöriger OneSurvey-Account zur E-Mail-Adresse gefunden.", 'error')
                }
                else if(text === "No Role found."){
                    showToast("Die Berechtigungskategorie steht derzeit leider nicht zur Verfügung.", 'error')
                }
                else if (text === `Share for Account ${formData.get('email')} already existend. To upgrade permissions, please remove old share.`){
                    showToast(`Dem Benutzer ${formData.get('email')} steht bereits eine Freigabe zur Verfügung. Um die Berechtigung zu ändern, bitte die bestehende Freigabe löschen und neu zuweisen.`, 'error')
                }
                else if(text === "No Surveys found."){
                    showToast("Sie besitzen keine Berechtigung, diese Umfrage zu teilen. Bitte kontaktieren Sie dafür den Umfragenbesitzer.", 'error')
                }
                else{
                    showToast(text, 'error')
                }
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                console.log("Survey shared successfully.");
                setIsSubmitting(false);
                showToast(`Umfrage ${surveys.find((survey) => survey.id === currentSurvey)?.title} erfolgreich an Benutzer ${formData.get('email')} freigegeben.`, 'success')
                fetchShares(currentSurvey);
            }

        } catch (error) {
            setIsSubmitting(false);
            console.log(`Error ${error}`);
        }
    };

    const removeShare = async (shareId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${currentSurvey}/share/${shareId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                showToast('Fehler beim Löschen der Freigabe', 'error')
                throw new Error("Fehler beim Löschen der Freigabe");
            }
            else{
                fetchShares(currentSurvey);
                showToast('Freigabe erfolgreich gelöscht', 'success')
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    const SharePopup = ({ surveyId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Umfrage freigeben</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Teilen Sie die Umfrage "{surveys.find((survey) => survey.id === surveyId)?.title || 'Information not found.'}" mit einer anderen Person.<br/>Bitte wählen Sie zuerst die Berechtigung aus.</p>
                    <form onSubmit={handleShare}>
                        <label>Berechtigungsstufe:</label>
                        <select
                            name="role"
                            required
                        >
                            <option value="" disabled>
                                Bitte wählen Sie eine Berechtigung aus.
                            </option>
                            {roles.map((type) => (
                                <option key={type.id} value={type.code}>
                                    {type.code} - {type.description}
                                </option>
                            ))}
                        </select>
                        <div className="spacer"></div>
                        <div>
                            <label>E-Mail:</label>
                            <input
                                type="email"
                                placeholder="Mit welchem Account, möchten Sie diese Umfrage teilen?"
                                name="email"
                                required
                            />
                        </div>
                        <div className="spacer4"></div>
                        <span style={{ display: "flex", alignContent: "center", gap: "10px"}}>
                            <div>
                                <button id="btn-primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Umfrage wird freigegeben...' : 'Umfrage teilen'}
                            </button>
                            </div>
                            <div>
                                <p className={`message ${messageStatus}`}>{message}</p>
                            </div>
                        </span>
                    </form>
                    {existingShares ?
                        <div>
                            <div className="divider"></div>
                            <h2>Bestehende Freigaben</h2>
                            <div className="list" id="share-list">
                                {shares.map((share) => (
                                    <div className="list-item share-item" id={share.id} style={{background: "rgba(227,227,227,0.16)"}}>
                                        <div className="item-description">
                                            <a href={`mailto:` + share.sharedAccount_Email} style={{textDecoration: "none"}}>
                                                <h3>{share.sharedAccount_Firstname}&nbsp;{share.sharedAccount_Lastname}</h3>
                                            </a>
                                            <p>Berechtigung: {share.roleCode} - Erstellt: {share.convertedCreationTime} Uhr</p>
                                        </div>
                                        <div className="item-controls" onClick={()=>{removeShare(share.id)}} style={{alignContent: "center"}}>
                                            <RemoveIcon
                                                size={"60px"}
                                                fill={"none"}
                                                color={"currentColor"}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div>
                            <div className="spacer"></div>
                            <p>Diese Umfrage wurde noch nicht geteilt.</p>
                        </div>
                    }
                </div>
            </div>
        );
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs"><a href='/'>Home &gt;</a> <a href={`/mysurveys/`}>Meine Umfragen</a></span>
                <h1>Meine Umfragen</h1>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Hier können Sie Ihre Umfragen einsehen.</p>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <button className="btn-primary" onClick={() => window.location.href = "/setup"}>
                            Umfrage erstellen
                        </button>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="grid-list">
                    {surveys.map((survey) => (
                        <div key={survey.id} className="card">
                            <a href={`/mysurveys/${survey.code}`} style={{textDecoration: "none"}}>
                                <h3 style={{minHeight: "80px"}}>{survey.title}</h3>
                                <p className="secondaryText" id="sharinginformation">{survey.isSurveyShared ? "Mit Ihnen geteilt" : ""}</p>
                            </a>
                            <a href={`/mysurveys/${survey.code}`} class="tooltip">
                                <EditIcon size={"30px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Umfrage bearbeiten</span>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href={`/dashboard/${survey.code}`} class="tooltip">
                                <DataInsight size={"30px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Einträge öffnen</span>
                            </a>
                            {survey.isSurveyShared ?
                                <a></a>
                                :
                                <a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class="tooltip" onClick={()=>openSharePopup(survey.id)}>
                                        <ShareIcon size={"30px"} fill={"none"} color={"currentColor"}/>
                                        <span className="tooltiptext">Umfrage teilen</span>
                                    </a>
                                </a>
                            }
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href={`https://app.onesurvey.de/s/${survey.code}`} target="_blank" class="tooltip">
                                <OpenTabIcon size={"30px"} fill={"none"} color={"currentColor"}/>
                                <span className="tooltiptext">Umfrage öffnen</span>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="spacer"></div>
            </div>

            {sharePopupStatus && (
                <SharePopup
                    surveyId={currentSurvey}
                    onClose={() => setSharePopup(false)}
                />
            )}

            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default MySurveys;
