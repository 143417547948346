import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import PublicHeader from "../../components/PublicHeader";
import CheckIcon from "../../components/UIComponents/icons/Check";
import PublicFooter from "../../components/PublicFooter";
import { useCookies } from "react-cookie";

const Logout = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);

    useEffect(() => {
        const logout = async () => {
            sessionStorage.removeItem('OSACCESS');
            removeCookie('accessToken', { path: '/' });
            localStorage.setItem('logout', Date.now());
        };
        logout();
    }, [removeCookie]);

    return (
        <div>
            <PublicHeader />
            <div className="app-container">
                <CheckIcon size={"80px"} fill={"none"} color={"yellowgreen"} />
                <h2>Sie haben sich erfolgreich abgemeldet! Vielen Dank und einen schönen Tag.</h2>
                <button onClick={() => navigate('/')}>Zurück zur App</button>
                <div className="spacer"></div>
                <div className="divider"></div>
                <div className="spacer"></div>
                <h1>Entdecken Sie noch mehr:</h1>
                <div className="grid-dashboard">
                    <div className="card" style={{height: "auto"}}>
                        <h3>OneSurvey Integration bei Ihrer Webseite</h3>
                        <p>Wir integrieren Ihre Umfragen in Ihre bestehende Webseite.</p>
                        <a href="https://onesurvey.de/">Zum Angebot</a>
                    </div>
                    <div className="card" style={{height: "auto"}}>
                        <h3>OneSurvey OnPremise</h3>
                        <p>Maximale Datenhoheit bei Ihren Umfragen. Ihre eigene OneSurvey Instanz auf Ihren Servern!</p>
                        <a href="https://onesurvey.de/">Erfahren Sie mehr</a>
                    </div>
                    <div className="card" style={{height: "auto"}}>
                        <h3>Weitere Produkte der OneCoding</h3>
                        <p>Entdecken Sie unsere weiteren Produkte für Ihren digitalen Erfolg!</p>
                        <a href="https://onecoding.de/">Jetzt mehr erfahren</a>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="spacer1"></div>
            </div>
            <PublicFooter />
        </div>
    );
};

export default Logout;