import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import "./Shop.css";
import LoadingScreen from "../../../components/UIComponents/screens/LoadingScreen";
import {useNavigate} from "react-router-dom";
import WarningIcon from "../../../components/UIComponents/icons/Warning";

const Shop = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch("https://api.onesurvey.de/v1/e-shop/products",{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Produkte");
                }

                const data = await response.json();
                setProducts(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (loading) {
        return <LoadingScreen/>;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Ein Fehler ist aufgetreten.</h1>
                    <p>Der OneCoding Shop konnte derzeit nicht abgerufen werden. Bitte laden Sie die Seite erneut oder versuchen es später erneut.</p>
                    <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                        Zurück zur Übersicht
                    </button>
                </div>
            </div>
        );    }

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <h1 style={{textAlign: "center"}}>Wählen Sie Ihren passenden Tarif, um mit OneSurvey zu starten</h1>
                <div className="grid-dashboard product-grid">
                    {products.map(product => (
                        <div key={product.id} className="card product-card">
                            <h2 className="product-title">{product.title}</h2>
                            <p className="product-price">{product.price > 0 ? `${product.price.toFixed(2).replace('.', ',')} €` + `${product.type === "Monthly" ? " / Monat" : ""}` : "Kostenlos"}</p>
                            <p className="product-description secondaryText">{product.description || "Keine Beschreibung verfügbar."}</p>
                            <p>{product.duration > 1 ? "Laufzeit: " + product.duration + " Monate": "Laufzeit: " + product.duration + " Monat"}</p>
                            <p>{product.autoRenew ? "Automatische Verlängerung" : "Keine automatische Verlängerung"}</p>
                            <button onClick={()=>navigate(`/e-shop/${product.code}`)} className="btn btn-primary buy-button">Produkt auswählen</button>
                        </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default Shop;