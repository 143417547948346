import './Icon.css';

const CloseIcon = ({size, fill, color}) => (

    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        id="close"
        alt="close"
        className="icon"
    >
        <path d="M6 6L18 18M18 6L6 18"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
        />
    </svg>
)

export default CloseIcon;